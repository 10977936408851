import React, { useState, useContext } from "react"; 
import { Link as RouterLink } from "react-router-dom";

import {
    TextField,
    InputAdornment,
    IconButton,
    makeStyles
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import './style.css';

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";

import wave from './img/wave.png';
import bg from './img/bg.svg';
import logo from '../../assets/logo.png'; 

import Link from "@material-ui/core/Link"; 
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    labelCustom: {
      color: "grey",
    },
}));

const Copyright = () => {
    const classes = useStyles();
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            <Link
                className={classes.labelCustom}
                href={process.env.REACT_APP_LANDING_PAGE_URL} 
            >
                {"© "} {new Date().getFullYear()} {process.env.REACT_APP_COPYRIGHT}
            </Link>
        </Typography>
    );
};

const Login = () => {      
    const [user, setUser] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);

    const { handleLogin } = useContext(AuthContext);

    const handleChangeInput = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handlSubmit = (e) => {
        e.preventDefault();
        handleLogin(user);
    };

    const handleLinkClick = () => {
        const owner = process.env.REACT_APP_LANDING_PAGE_URL.toUpperCase();
        window.location.href = owner.includes('WATENDE') ? 'https://www.watende.com.br/index.html#trial' : '#';
      };

    return (
        <>
            {/* eslint-disable-next-line */}
            <img className="wave" src={wave} />
            <div className="container">
                {/* eslint-disable-next-line */}
                <div className="img">
                    {/* eslint-disable-next-line */}
                    <img 
                        src={bg}
                        alt="Bg" 
                        onContextMenu={(e) => e.preventDefault()} // Impede o menu de contexto (botão direito)
                        draggable="false" // Impede que a imagem seja arrastada                
                    />   
                </div>
                <div className="login-content">
                    <form noValidate onSubmit={handlSubmit}>
                        {/* eslint-disable-next-line */} 
                        <img 
                            src={logo}
                            alt="logo" 
                            onContextMenu={(e) => e.preventDefault()} // Impede o menu de contexto (botão direito)
                            draggable="false" // Impede que a imagem seja arrastada                
                        />                        
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={i18n.t("login.form.email")}
                            name="email"
                            value={user.email}
                            onChange={handleChangeInput}
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={i18n.t("login.form.password")}
                            id="password"
                            value={user.password}
                            onChange={handleChangeInput}
                            autoComplete="current-password"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword((e) => !e)}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <input type="submit" className="btn" value="Acessar" />
                        {process.env.REACT_APP_LANDING_PAGE_URL.toUpperCase().includes('WATENDE') && (
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link 
                                        variant="body2"
                                        component={RouterLink}
                                        onClick={handleLinkClick}
                                        to=""
                                    >
                                        {i18n.t("login.buttons.register")}
                                    </Link>
                                </Grid>
                            </Grid>
                        )}
                        <Box mt={1}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Login;
