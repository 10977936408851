import React, { useState, useEffect, useReducer, useRef, useContext } from "react";

import { isSameDay, parseISO, format } from "date-fns";
import clsx from "clsx";

import { green } from "@material-ui/core/colors";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
  Tooltip
} from "@material-ui/core";

import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
  Facebook,
  Instagram,
  Forward
} from "@material-ui/icons";
import { MdCall, MdLink, MdArrowForward } from "react-icons/md";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";
import MarkdownWrapper from "../MarkdownWrapper";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";
import whatsBackgroundDark from "../../assets/wa-background_dark.png";

import api from "../../services/api";
import toastError from "../../errors/toastError"; 
import { Mutex } from "async-mutex";

import YouTubePreview from "../ModalYoutubeCors";
import { isYouTubeLink } from "../../helpers/perso";
import { SocketContext } from "../../context/Socket/SocketContext";
import ButtonWithSpinner from "../ButtonWithSpinner";

const loadPageMutex = new Mutex();

const useStyles = makeStyles(theme => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    minWidth: 300,
    minHeight: 200
  },

  messagesList: {
    backgroundImage:
      theme.mode === "dark"
        ? `url(${whatsBackgroundDark})`
        : `url(${whatsBackground})`,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    ...theme.scrollbarStyles
  },
  dragElement: {
    background: "rgba(255, 255, 255, 0.8)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 999999,
    textAlign: "center",
    fontSize: "3em",
    border: "5px dashed #333",
    color: "#333",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.mode === "dark" ? "#1e303bfc" : "#ffffff",
    color: theme.mode === "dark" ? "white" : "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3"
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: theme.mode === "dark" ? "#202c33fc" : "#f0f0f0",
    color: "grey",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative"
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden"
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef"
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.mode === "dark" ? "#005c4bff" : "#dcf8c6",
    color: theme.mode === "dark" ? "white" : "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageRightPrivate: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0
    },
    whiteSpace: "pre-wrap",
    backgroundColor: "#F0E68C",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow:
      theme.mode === "light" ? "0 1px 1px #b3b3b3" : "0 1px 1px #000000"
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: theme.mode === "dark" ? "#044539" : "#cfe9ba",
    color: "grey",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative"
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap"
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96"
  },

  // messageActionsButton: {
  //   display: "none",
  //   position: "relative",
  //   color: "#999",
  //   zIndex: 1,
  //   backgroundColor: "inherit",
  //   opacity: "90%",
  //   "&:hover, &.Mui-focusVisible": { 
  //     backgroundColor: "inherit",
  //     "& svg": {  // Aplica a cor apenas ao ícone dentro do botão
  //       color: "#25D366"
  //     }
  //   }
  // },

  messageActionsButton: {
    display: "none",  // Certifique-se de que o botão não será escondido
    position: "relative",
    color: "#999",  // Cor do ícone padrão (cinza)
    zIndex: 1,
    backgroundColor: "inherit",  // Mantém o fundo transparente para o ícone
    opacity: "80%",
    borderRadius: "50%",  // Garante bordas arredondadas
    padding: "7px",  // Ajusta o tamanho da área de clique
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: theme.mode === "dark" ? "#999" : theme.palette.primary.main,  // Cor de fundo verde (ou cinza no modo escuro)
      opacity: 1,  // Opacidade 100% ao passar o mouse
      "& svg": {  // Altera a cor do ícone
        color: "#fff"  // Cor do ícone para branco ao passar o mouse
      }
    },
    // Estilo para o ícone ExpandMore dentro do IconButton
    "& .MuiIconButton-root": {
      fontSize: "20px",  // Reduz o tamanho do ícone
      color: "#999",  // Define a cor do ícone inicialmente como cinza
    }
  },
  
  smallIcon: {
    fontSize: "16px !important",  // Tamanho do ícone menor
  },  

  messageAlert: {
    width: "100%",
    display: "flex",
    padding: "10px",
    alignItems: "center",
    backgroundColor: theme.mode === "dark" ? "#006400" : "#fff",
    color: "#FFFFFF"
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500
  },

  messageButtonsMsg: {
    padding: 10,
    maxWidth: 500,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden"
  },

  messageButtons: {
    width: "100%",
    display: "flex",
    fontSize: "1.05rem",
    fontWeight: 500
  },

  messageButtonsSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "gray"
  },

  forwardedMessage: {
    display: "flex",
    color: theme.mode === 'light' ? "#999" : "#d0d0d0",
    fontSize: 11,
    fontWeight: 'bold'
  },

  forwardedIcon: {
    color: theme.mode === 'light' ? "#999" : "#d0d0d0",
    fontSize: 15,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px"
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "grey", //"rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px"
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  },

  messageVideo: {
    width: 250,
    maxHeight: 445,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  },
 
  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
    userSelect: "none"
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3"
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px"
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4
  },

  ackDoneAllIcon: {
    color: green[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10
  },

  invertedFabMenuBase: {
    borderRadius: 50, // Valor padrão, pode ser sobrescrito
    boxShadow: "none", // Remove a sombra
    width: theme.spacing(4), // Ajuste o tamanho de acordo com suas preferências
    height: theme.spacing(4),
    backgroundColor: "transparent",
    color: "grey",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.4)", // Animação de escala
      color: theme.mode === "light" ? theme.palette.primary.main : "#EEE"
    },
    "&:disabled": {
      backgroundColor: "transparent !important"
    }
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach(message => {
      const messageIndex = state.findIndex(m => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex(m => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex(m => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MessagesList = ({
  ticket,
  ticketId,
  isGroup,
  showSelectMessageCheckbox,
  setShowSelectMessageCheckbox,
  setSelectedMessagesList,
  selectedMessagesList,
  forwardMessageModalOpen,
  setForwardMessageModalOpen,
  onDrop
}) => {
  const classes = useStyles();

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();

  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);
  const [contactPresence, setContactPresence] = useState("available");

  const [dragActive, setDragActive] = useState(false);
 
  const socketManager = useContext(SocketContext);

  // useEffect(() => {
  //   dispatch({ type: "RESET" });
  //   setPageNumber(1);
  //   setContactPresence("available");

  //   currentTicketId.current = ticketId;
  // }, [ticketId]);

  // useEffect(() => {
  //   const loadData = async (incrementPage = false) => {
  //     // Mantenha o valor correto da página, sem resetá-la a 1 a cada render
  //     const thisPageNumber = incrementPage ? pageNumber + 1 : pageNumber;

  //     setLoading(true);

  //     await loadPageMutex.runExclusive(async () => {
  //       const delayDebounceFn = setTimeout(async () => {
  //         if (ticketId === undefined) return;

  //         try {
  //           const { data } = await api.get("/messages/" + ticketId, {
  //             params: { pageNumber: thisPageNumber } // Use o valor atualizado da página
  //           });

  //           if (currentTicketId.current === ticketId) {
  //             dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
  //             setHasMore(data.hasMore); // Define se há mais mensagens para carregar
  //             setLoading(false);

  //             // Atualiza o número da página apenas quando incrementado
  //             if (incrementPage) {
  //               setPageNumber(thisPageNumber);
  //             }
  //           }

  //           if (thisPageNumber === 1 && data.messages.length > 1) {
  //             scrollToBottom(); // Rolagem para o fim somente na primeira página
  //           }
  //         } catch (err) {
  //           setLoading(false);
  //           toastError(err);
  //         }
  //       }, 500);

  //       return () => {
  //         clearTimeout(delayDebounceFn);
  //       };
  //     });
  //   };

  //   loadData(); // Chame a função de carregamento de dados sem resetar a página
  // }, [ticketId, pageNumber]); // Escute mudanças no número da página



  function loadData(incrementPage = false) {
    setLoading(true);
    const thisPageNumber = incrementPage ? pageNumber + 1 : 1;
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        if (ticketId === undefined) return;
        try {
          const { data } = await api.get("/messages/" + ticketId, {
            params: { pageNumber: thisPageNumber },
          });

          if (currentTicketId.current === ticketId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            scrollToBottom();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
      setPageNumber(thisPageNumber);
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "RESET" });
      setContactPresence("available");
  
      currentTicketId.current = ticketId;
  
      await loadPageMutex.runExclusive(async () => {
        await loadData();
      });
    };
  
    fetchData();
  // eslint-disable-next-line 
  }, [ticketId]); 

  useEffect(() => {
    if (!ticket.id) {
      return;
    }
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.GetSocket(companyId);

    const onConnect = () => {
      socket.emit("joinChatBox", `${ticket.id}`);
    }

    socketManager.onConnect(onConnect);

    // socket.on(`company-${companyId}-appMessage`, data => {
    //   setContactPresence("available");

    //   if (data.action === "create") {
    //     dispatch({ type: "ADD_MESSAGE", payload: data.message });
    //     scrollToBottom();
    //   }

    //   if (data.action === "update") {
    //     dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
    //   }
    // });

    const onAppMessage = (data) => {
      if (data.message.ticketId === currentTicketId.current) {
        setContactPresence("available");
        if (data.action === "create") {
          dispatch({ type: "ADD_MESSAGE", payload: data.message });
          scrollToBottom();
        }

        if (data.action === "update") {
          dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
        }
      }
    }

    socket.on(`company-${companyId}-appMessage`, onAppMessage);

    socket.on(`company-${companyId}-presence`, data => {
      const { scrollTop, clientHeight, scrollHeight } = lastMessageRef.current;
      // console.log({ presence: data.presence, scrollTop, clientHeight, scrollHeight });
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - clientHeight / 4;

      if (data?.ticketId === ticket.id) {
        setContactPresence(data.presence);

        if (["composing", "recording"].includes(data.presence)) {
          if (isAtBottom) {
            scrollToBottom();
          }
        }
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, ticket, socketManager]);

  const loadMore = async () => {
    if (!hasMore || loading) return; // Evita novas requisições se já está carregando
    await loadPageMutex.runExclusive(() => {
      // setPageNumber(prevPageNumber => prevPageNumber + 1);
      loadData(true);
    });
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = e => {
    if (!hasMore) return;
    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = e => {
    setAnchorEl(null);
  };

  const typeMediaUrl = nomeDoArquivo => {
    if (!nomeDoArquivo) return;
    const extensao = nomeDoArquivo.split(".").pop().toLowerCase();

    const tiposDeMidiaPorExtensao = {
      jpg: "Imagem",
      jpeg: "Imagem",
      png: "Imagem",
      gif: "Imagem",
      mp4: "Vídeo",
      avi: "Vídeo",
      mkv: "Vídeo",
      mov: "Vídeo",
      mpeg: "Vídeo",
      mp3: "Áudio",
      ogg: "Áudio",
      wav: "Áudio",
      pdf: "Documento",
      doc: "Documento",
      docx: "Documento",
      xls: "Documento",
      xlsx: "Documento",
      txt: "Documento",
      csv: "Documento",
      ppt: "Documento",
      pptx: "Documento",
      pps: "Documento",
      ppsx: "Documento"
      // Adicione mais extensões conforme necessário
    };

    return tiposDeMidiaPorExtensao[extensao] || "";
  };

  const renderVCard = (messageBody, isQuotedMsgRender) => {
    let array = messageBody.split("\n");
    let obj = [];
    let contact = "";
    for (let index = 0; index < array.length; index++) {
      const v = array[index];
      let values = v.split(":");
      for (let ind = 0; ind < values.length; ind++) {
        if (values[ind].indexOf("+") !== -1) {
          obj.push({ number: values[ind] });
        }
        if (values[ind].indexOf("FN") !== -1) {
          contact = values[ind + 1];
        }
      }
    }
    return (
      <VcardPreview
        contact={contact}
        numbers={obj[0]?.number}
        ticket={ticket}
        isQuotedMsgRender={isQuotedMsgRender}
      />
    );
  };

  const checkMessageMedia = (message, isQuotedMsgRender) => {
    // console.log('tipo de media: '+ message.mediaType);
    // console.log('message.mediaUrl: '+ message.mediaUrl);
    // console.log('typeMediaUrl(message.mediaUrl): '+ typeMediaUrl(message.mediaUrl));
    // console.log('isQuotedMsgRender: '+ JSON.stringify(isQuotedMsgRender));

    if (isQuotedMsgRender) {
      checkMessageMedia(isQuotedMsgRender, false);
    }

    if (message.mediaType === "locationMessage") {
      try {
        let locationParts = message?.body?.split("|");

        if (!(locationParts.length >= 2)) return;

        let imageLocation = locationParts[0];
        let linkLocation = locationParts[1];

        let descriptionLocation = null;

        if (locationParts.length > 2) {
          descriptionLocation = locationParts[2];
        }
        return (
          <LocationPreview
            image={imageLocation}
            link={linkLocation}
            description={descriptionLocation}
            isQuotedMsgRender={isQuotedMsgRender}
          />
        );
      } catch (err) {
        //console.log("checkMessageMedia ~ err", err)
      }
    } else if (message.mediaType === "contactMessage") {
      return renderVCard(message.body, false);
    } else if (
      message.mediaType === "image" ||
      typeMediaUrl(message.mediaUrl) === "Imagem"
    ) {
      return <ModalImageCors imageUrl={message.mediaUrl} />;
    } else if (
      message.mediaType === "audio" ||
      typeMediaUrl(message.mediaUrl) === "Áudio"
    ) {
      return (
        <audio controls>
          <source src={message.mediaUrl} type="audio/ogg"></source>
        </audio>
      );
    } else if (
      message.mediaType === "video" ||
      typeMediaUrl(message.mediaUrl) === "Vídeo"
    ) {
      return (
        <video
          className={classes.messageVideo}
          src={message.mediaUrl}
          controls
        />
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              variant="outlined"
              color="secondary"
              target="_blank"
              href={message.mediaUrl}
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
  };

  const renderMessageAck = message => {
    if (message.ack === 0 || message.ack === 1) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const getIcon = (type, isTemplateMessage) => {
    if (!isTemplateMessage) return null;
    switch (type) {
      case "callButton": return <MdCall size={18} />;
      case "urlButton": return <MdLink size={18} />;
      default: return <MdArrowForward size={18} />;
    }
  };

  const renderQuotedMessage = (message) => {
    if (!message) {
      return null;
    }
    // console.log('message.quotedMsg:\n'+ JSON.stringify(message.quotedMsg));

    const isTemplateMessage = ['templateButtonReplyMessage'].includes(message.mediaType);
    const data = JSON.parse(message.dataJson);
    const dataMsg = data?.message;    
    const displayText = dataMsg?.templateButtonReplyMessage?.selectedDisplayText;
    
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              <MarkdownWrapper>
                {message.quotedMsg?.contact?.name}
              </MarkdownWrapper>
            </span>
          )}

          {message.quotedMsg?.mediaUrl ||
            message.quotedMsg?.mediaType === "locationMessage" ||
            message.quotedMsg?.mediaType === "contactMessage" ? 
            (checkMessageMedia(message, message.quotedMsg)) 
              : 
            (
              <MarkdownWrapper>
                {isTemplateMessage ? displayText : message.quotedMsg?.body}
              </MarkdownWrapper>
            )
          }
        </div>
      </div>
    );
  };

  const getDataContextInfo = (data) => {
    if (!data) {
      return null;
    }
    
    const response = data.message?.extendedTextMessage?.contextInfo ||
      data.message?.imageMessage?.contextInfo ||
      data.message?.videoMessage?.contextInfo ||
      data.message?.audioMessage?.contextInfo ||
      data.message?.documentMessage?.contextInfo ||
      data.message?.stickerMessage?.contextInfo ||
      data.message?.productMessage?.contextInfo ||
      data.message?.locationMessage?.contextInfo ||
      data.message?.liveLocationMessage?.contextInfo ||
      data.message?.contactMessage?.contextInfo ||
      data.message?.listMessage?.contextInfo ||
      data.message?.buttonsMessage?.contentText ||
      data.message?.buttonsResponseMessage?.contextInfo ||
      data.message?.paymentMessage?.contextInfo ||
      data.message?.orderMessage?.contextInfo ||
      data.message?.productCatalogMessage?.contextInfo ||
      data.message?.templateButtonReplyMessage?.contextInfo ||
      data.message?.templateMessage?.contextInfo ||
      data.message?.documentWithCaptionMessage?.contextInfo || null;

      // console.log('response', response);

    return response;
  };

  const removeEmojis = (text) => {
    return text.replace(/[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu, '').trim();
  };
  
  const renderButtonsMessage = (data) => {   
    if (!data) return;
    const messageData = JSON.parse(data.dataJson);
    const message = messageData.message;
    
    if (!message) return null;
    
    const isTemplateMessage = !!message.templateMessage;
    
    const buttons = message.buttonsMessage?.buttons ||
      message.listMessage?.sections.flatMap(section => section.rows.map(row => ({
        buttonId: row.rowId,
        buttonText: { displayText: row.title },
        description: row.description,
        type: "RESPONSE",
      }))) ||
      message.templateMessage?.hydratedTemplate?.hydratedButtons.map(button => {
        const key = Object.keys(button)[0];
        return {
          buttonId: button[key].id,
          buttonText: { displayText: button[key].displayText },
          type: key,
        };
      }) || [];
    
    if (buttons.length === 0) return null;
    
    const handleClick = (button) => {
      if (!button) return;

      let textButton = removeEmojis(button?.buttonText?.displayText);
      if (button.type === "quickReplyButton") {
        textButton = button.buttonId;
      }
      if (button.type === "urlButton") {
        window.open(button.buttonId, "_blank");
        return;
      }
      if (button.type === "callButton") {
        window.location.href = `tel:${button.buttonId}`;
        return;
      }
            
      handleSendMessageForButton(textButton, messageData.key);
    };

    const maxTextLength = Math.max(...buttons.map(button => button.buttonText.displayText.length));
    const dynamicMinWidth = Math.max(maxTextLength * 10, 150);
    const shouldStackButtons = buttons.length > 2;
    
    const messageFooter = message.templateMessage?.hydratedTemplate?.hydratedFooterText;
    const existMessageFooter = isTemplateMessage && messageFooter !== '';

    return (
      <>
        <div className={clsx(classes.quotedContainerLeft, { [classes.quotedContainerRight]: message.fromMe })}>
          <span className={classes.messageButtonsSideColorLeft}></span>
          <div className={classes.messageButtonsMsg}>
            <span className={classes.messageButtons} >
              <MarkdownWrapper>{
                message.buttonsMessage?.contentText || 
                message.listMessage?.description || 
                message.templateMessage?.hydratedTemplate?.hydratedContentText
              }</MarkdownWrapper>
            </span>
          </div> 
        </div>
        <Divider style={{ backgroundColor: "#ccc", height: "1px" }} />
        {existMessageFooter && (
          <div
            style={{
              fontSize: "14px", // Fonte menor
              color: "#777", // Cinza escuro para um tom mais discreto
              fontWeight: "normal", // Remove qualquer negrito
              fontStyle: "normal", // Remove qualquer itálico
              padding: "4px 0", // Ajusta o espaçamento para um visual mais fino
              textAlign: "center", // Centraliza o texto
            }}
          >
            {messageFooter}
            <Divider />
          </div>
        )}
        <div style={{ 
          display: "flex", 
          flexDirection: shouldStackButtons ? "column" : "row", 
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "8px", 
          alignItems: "center",
          width: "100%",
          marginTop: "10px"
        }}>
          {buttons.map((button) => (
            <Tooltip key={button.buttonId} title={button.description || ""} arrow>
              <span>
                <ButtonWithSpinner 
                  className={classes.invertedFabMenuBase}
                  type="button"
                  loading={loading}
                  variant="outlined" 
                  autoFocus
                  onClick={() => handleClick(button)}
                  style={{ 
                    minWidth: `${dynamicMinWidth}px`,
                    fontSize: "0.8rem",
                    padding: "8px 15px",
                    borderRadius: "8px",
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    gap: "8px"
                  }}
                >
                  {isTemplateMessage && getIcon(button.type, isTemplateMessage)} {button.buttonText.displayText}
                </ButtonWithSpinner>
              </span>
            </Tooltip>
          ))}
        </div>  
      </>
    );
  };

  const renderButtonsMessageResponse = (message) => {
    if (!message) {
      return null;
    }

    const data = JSON.parse(message.dataJson);
    const dataMsg = data?.message;

    // const buttonId = dataMsg?.templateButtonReplyMessage?.selectedId || 
    //   dataMsg?.listResponseMessage?.singleSelectReply?.selectedRowId ||
    //   dataMsg?.buttonsResponseMessage?.selectedButtonId;
    const displayText = dataMsg?.templateButtonReplyMessage?.selectedDisplayText || 
      dataMsg?.listResponseMessage?.title ||
      dataMsg?.buttonsResponseMessage?.selectedDisplayText;

    const isToButton = ['templateButtonReplyMessage', 'buttonsResponseMessage', 'listResponseMessage'].includes(message.mediaType);
    // const isTemplateMessage = ['templateButtonReplyMessage'].includes(message.mediaType);

    if (!isToButton || !displayText) return null;

    // const dynamicMinWidth = Math.max(displayText.length * 10, 150);

    return (
      <>
        <MarkdownWrapper>{
          displayText
        }</MarkdownWrapper>      
        {/* <div style={{ 
          display: "flex", 
          flexDirection: "row", 
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "8px", 
          alignItems: "center",
          width: "100%",
          marginTop: "10px"
        }}>
          { <Tooltip key={buttonId} title={""} arrow>
              <span>
                <ButtonWithSpinner 
                  className={classes.invertedFabMenuBase}
                  type="button"
                  disabled={isToButton}
                  loading={loading}
                  variant="outlined"
                  style={{ 
                    minWidth: `${dynamicMinWidth}px`,
                    fontSize: "0.8rem",
                    padding: "8px 15px",
                    borderRadius: "8px",
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    gap: "8px"
                  }}
                >
                  {isTemplateMessage && getIcon('', isTemplateMessage)} {displayText}
                </ButtonWithSpinner>
              </span>
            </Tooltip>
          }
        </div> */}
      </>
    );
  }

  const handleSendMessageForButton = async (inputMessage, replyingMessage) => {
    if (inputMessage === "" || !replyingMessage) return;
    setLoading(true);
 
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: inputMessage,
      quotedMsg: replyingMessage,
      isPrivate: false
    };

    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      toastError(err);
    } 
    setLoading(false);
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        const data = JSON.parse(message.dataJson);
        const dataContext = getDataContextInfo(data);
        const noBody = ['locationMessage', 'contactMessage', 'buttonsMessage', 'listMessage', 'templateMessage'].includes(message.mediaType);
        const isButton = ['buttonsMessage', 'listMessage', 'templateMessage'].includes(message.mediaType);
        const isResponseButton = ['templateButtonReplyMessage', 'buttonsResponseMessage', 'listResponseMessage'].includes(message.mediaType);
        
        if (!message.fromMe) {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div id={message.id} 
                className={classes.messageLeft}
                title={message.queueId && message.queue?.name}
              >
                <SelectMessageCheckbox
                  showSelectMessageCheckbox={showSelectMessageCheckbox}
                  message={message}
                />
                <IconButton
                  variant="contained"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={e => handleOpenMessageOptionsMenu(e, message)}
                  disableFocusRipple
                >
                  <ExpandMore className={classes.smallIcon} />
                </IconButton>
                { dataContext?.isForwarded && (
                  <span className={classes.forwardedMessage}>
                    <Forward fontSize="small" className={classes.forwardedIcon}/> {"Encaminhada "}
                  </span>
                )}
                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}
                 
                {isYouTubeLink(message.body) && (
                  <>
                    <YouTubePreview videoUrl={message.body} />
                  </>
                )}
                {(message.mediaUrl ||
                  message.mediaType === "locationMessage" ||
                  message.mediaType === "contactMessage") &&
                  checkMessageMedia(message, message.quotedMsg)}
                <div className={classes.textContentItem}>
                  {message.quotedMsg && renderQuotedMessage(message)}
                  {isButton && renderButtonsMessage(message)}
                  <MarkdownWrapper>
                    {noBody ? null : message.body}
                  </MarkdownWrapper>
                  {(message.mediaUrl ||
                    (message.mediaType !== "extendedTextMessage" &&
                      message.mediaType !== "conversation")) &&
                    " "}
                  <span className={classes.timestamp}>
                    {message.editedMessage === true ? "Editada " : ""}
                    {format(parseISO(message.createdAt), "HH:mm")}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div id={message.id}
                className={message.isPrivate ? classes.messageRightPrivate : classes.messageRight}
                title={message.queueId && message.queue?.name}
              >
                <SelectMessageCheckbox
                  showSelectMessageCheckbox={showSelectMessageCheckbox}
                  message={message}
                />
                <IconButton
                  variant="contained"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={e => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore className={classes.smallIcon} />
                </IconButton>
                {isYouTubeLink(message.body) && (
                  <>
                    <YouTubePreview videoUrl={message.body} />
                  </>
                )}
                {(message.mediaUrl ||
                  message.mediaType === "locationMessage" ||
                  message.mediaType === "contactMessage") &&
                  checkMessageMedia(message, message.quotedMsg)}
                
                { dataContext?.isForwarded && (
                  <span className={classes.forwardedMessage}>
                    <Forward fontSize="small" className={classes.forwardedIcon}/> {"Encaminhada"}
                  </span>
                )}
 
                <div
                  className={clsx(classes.textContentItem, {
                    [classes.textContentItemDeleted]: message.isDeleted
                  })}
                >
                  {message.isDeleted && (
                    <Block
                      color="disabled"
                      fontSize="small"
                      className={classes.deletedIcon}
                    />
                  )}
                  {message.quotedMsg && renderQuotedMessage(message)}
                  {isResponseButton ? (renderButtonsMessageResponse(message)
                  ) : (
                    <MarkdownWrapper>
                      {noBody ? null : message.body}
                    </MarkdownWrapper>
                  )}
                  {(message.mediaUrl ||
                    (message.mediaType !== "extendedTextMessage" &&
                      message.mediaType !== "conversation")) &&
                    " "}
                  <span className={classes.timestamp}>
                    {message.editedMessage === true ? "Editada " : ""}
                    {format(parseISO(message.createdAt), "HH:mm")}
                    {renderMessageAck(message)}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Diga "olá" para seu novo contato!</div>;
    }
  };

  const handleSelectMessage = (e, message) => {
    const list = selectedMessagesList;
    if (e.target.checked) {
      list.push(message);
    } else {
      if (list.length >= 4) {
        toastError({
          response: {
            data: {
              message:
                "Não é possível selecionar mais de 4 mensagens para encaminhar."
            }
          }
        });
        return;
      }
      const index = list.findIndex(m => m.id === message.id);
      list.splice(index, 1);
    }
    setSelectedMessagesList(list);
  };

  const SelectMessageCheckbox = ({ message, showSelectMessageCheckbox }) => {
    if (showSelectMessageCheckbox) {
      return (
        <Checkbox
          aria-label=""
          color="primary"
          onChange={e => handleSelectMessage(e, message)}
        />
      );
    } else {
      return null;
    }
  };

  const handleDrag = event => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false);
    }
  };
 
  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      if (onDrop) {
        onDrop(event.dataTransfer.files);
      }
    }
  };
 
  return (
    <div className={classes.messagesListWrapper} onDragEnter={handleDrag}>
      {dragActive && (
        <div
          className={classes.dragElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          Solte o arquivo aqui
        </div>
      )}

      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
        ticket={ticket}
        showSelectCheckBox={showSelectMessageCheckbox}
        setShowSelectCheckbox={setShowSelectMessageCheckbox}
        forwardMessageModalOpen={forwardMessageModalOpen}
        setForwardMessageModalOpen={setForwardMessageModalOpen}
        selectedMessages={selectedMessagesList}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
        ref={lastMessageRef}
      >
        {messagesList.length > 0 ? renderMessages() : []}
        {contactPresence === "composing" && (
          <div className={classes.messageLeft}>
            <div className={classes.wave}>
              <span className={classes.dot}>digitando...</span>
              <span className={classes.dot}></span>
              <span className={classes.dot}></span>
            </div>
          </div>
        )}
        {contactPresence === "recording" && (
          <div className={classes.messageLeft}>
            <div className={classes.wavebarsContainer}>
              <div className={clsx(classes.wavebars, classes.wavebar1)}>
                gravando áudio...
              </div>
              <div className={clsx(classes.wavebars, classes.wavebar2)}></div>
              <div className={clsx(classes.wavebars, classes.wavebar3)}></div>
              <div className={clsx(classes.wavebars, classes.wavebar4)}></div>
              <div className={clsx(classes.wavebars, classes.wavebar5)}></div>
            </div>
          </div>
        )}
      </div>
      {ticket?.channel !== "whatsapp" && (
        <div className={classes.messageAlert}>
          {ticket?.channel === "facebook" ? <Facebook /> : <Instagram />}

          <span>
            Você tem 24h para responder após receber uma mensagem, de acordo com
            as políticas do Facebook.
          </span>
        </div>
      )}
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
    </div>
  );
};

export default MessagesList;
