import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay, subDays } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line
import { green, grey } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper"; 
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { v4 as uuidv4 } from "uuid";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook"; 
import Telegram from "@material-ui/icons/Telegram";
import AndroidIcon from "@material-ui/icons/Android";
import VisibilityIcon from "@material-ui/icons/Visibility";
import HistoryIcon from '@material-ui/icons/History';
import TicketMessagesDialog from "../TicketMessagesDialog";
// eslint-disable-next-line
import UserAvatar from "../../pages/UserAvatar";
// eslint-disable-next-line
import { AiOutlineTags } from "react-icons/ai";
import ContactTag from "../ContactTag"; 
import { stringToColor } from "../../helpers/StringToColor";
import { Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import ShowTicketLogModal from "../ShowTicketLogModal";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    textAlign: "right",
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: 20,
  },

  newMessagesCount: {
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
    right: 20,
  },

  acceptButton: {
    position: "absolute",
    right: "108px",
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  ticketInfo: {
    textAlign: "right",
  },

  secondaryContentSecond: { 
    display: 'flex',
    alignItems: "flex-start",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignContent: "flex-start",
  },

  secondaryContentSecondTag: { 
    display: 'flex',
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "flex-start",
  },

  Radiusdot: {
    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3,
      fontSize: 10,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },
  },

  presence: {
    color: theme.mode === 'light' ? "green" : "lightgreen",
    fontWeight: "bold",
  }
}));

const TicketListItemCustom = ({ ticket, onButtonClick, userViewTicketsWithoutDepartment }) => {
  const classes = useStyles();
  const history = useHistory();  

  const [loading, setLoading] = useState(false);

  const [ticketUser, setTicketUser] = useState(null);
  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const [showTicketLogOpen, setShowTicketLogOpen] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);

  const { user } = useContext(AuthContext);
  const { profile } = user;

  useEffect(() => {
    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user.name);
    }

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });      
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
    onButtonClick && onButtonClick();
  };

  const handleSelectTicket = (ticket) => {

    if (profile !== 'admin' && (ticket.userId && ticket.userId !== user.id)) {
      toast.warn('Acesso não permitido.');
      return;
    }

    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
  };

  const renderTicketChannel = (ticket) => { 
    return (
      <> 
        {ticket.channel === "whatsapp" && (
          <Tooltip title={`Via whatsapp`}>
            <WhatsAppIcon fontSize="small" style={{ color: '#25D366' }} />
          </Tooltip>
        )}
        {ticket.channel === "telegram" && (
          <Tooltip title={`Via telegram`}>
            <Telegram fontSize="small" style={{ color: '#0088cc' }} />
          </Tooltip>
        )}
        {ticket.channel === "instagram" && (
          <Tooltip title={`Via instagram`}>
            <InstagramIcon fontSize="small" style={{ color: '#bc2a8d' }} />
          </Tooltip>
        )}
        {ticket.channel === "facebook" && (
          <Tooltip title={`Via facebook`}>
            <FacebookIcon fontSize="small" style={{ color: '#1877f2' }} />
          </Tooltip>
        )}
      </>
    );
  }

  const renderTicketInfo = () => {
    if (ticketUser) {
      return (
        <> 
          {profile === "admin" && (
            <>
              <Tooltip title="Ver os logs do ticket">
                <HistoryIcon
                  onClick={() => setShowTicketLogOpen(true)}
                  fontSize="small"
                  style={{
                    // color: grey[700],
                    cursor: "pointer",
                    marginRight: 5,
                  }}
                />
              </Tooltip>
              <Tooltip title="Ver conversa">
                <VisibilityIcon
                  onClick={() => setOpenTicketMessageDialog(true)}
                  fontSize="small"
                  style={{
                    // color: grey[700],
                    cursor: "pointer",
                    marginRight: 5,
                  }}
                />
              </Tooltip>
            </>
          )}
          {ticket.chatbot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ 
                  marginRight: 5 
                }}
              />
            </Tooltip>
          )} 
        </>
      );
    } else {
      return (
        <>      
          {ticket.chatbot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ 
                  // color: grey[700], 
                  marginRight: 5 
                }}
              />
            </Tooltip>
          )}
          {profile === "admin" && (
            <>            
              <Tooltip title="Ver os logs do ticket">
                <HistoryIcon
                  onClick={() => setShowTicketLogOpen(true)}
                  fontSize="small"
                  style={{
                    // color: grey[700],
                    cursor: "pointer",
                    marginRight: 5,
                  }}
                />
              </Tooltip>
              <Tooltip title="Ver conversa">
                <VisibilityIcon
                  onClick={() => setOpenTicketMessageDialog(true)}
                  fontSize="small"
                  style={{
                    // color: grey[700],
                    cursor: "pointer",
                    marginRight: 5,
                  }}
                />
              </Tooltip>
            </>
          )} 
        </>
      );
    }    
  };
 
  function renderizarData(ticket) {
    const dataAtual = new Date();
    const dataTicket = parseISO(ticket.updatedAt);
  
    if (isSameDay(dataTicket, dataAtual)) {
      // Se for o mesmo dia, renderiza apenas a hora
      return format(dataTicket, "HH:mm");
    } else if (isSameDay(subDays(dataAtual, 1), dataTicket)) {
      // Se for o dia anterior, renderiza "Ontem"
      return "Ontem";
    } else {
      // Se for em dias diferentes, renderiza a data completa
      return format(dataTicket, "dd/MM/yyyy");
    }
  }

  return (
    <React.Fragment key={ticket.id}>
      <TicketMessagesDialog
        open={openTicketMessageDialog}
        handleClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket.id}
        userViewTicketsWithoutDepartment={userViewTicketsWithoutDepartment}
      ></TicketMessagesDialog>
      {showTicketLogOpen && 
        <ShowTicketLogModal
          isOpen={showTicketLogOpen}
          handleClose={(e) => setShowTicketLogOpen(false)}
          ticketId={ticket.id}
        />
      }
      <ListItem
        dense
        button
        onClick={(e) => {
          if (ticket.status === "pending") return;
          handleSelectTicket(ticket);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <Tooltip
          arrow
          placement="right"
          title={ticket.queue?.name || "Sem fila"}
        >
          <span
            style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
            className={classes.ticketQueueColor}
          ></span>
        </Tooltip>
        <ListItemAvatar>
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <Avatar
            style={{
              marginTop: "-5px",
              marginLeft: "-3px",
              width: ticket.status !== "pending" ? "55px" : "50px",
              height: ticket.status !== "pending" ? "55px" : "50px",
              borderRadius: "100%",
            }}
            src={ticket?.contact?.profilePicUrl}
          />
          <Badge
            className={classes.newMessagesCount}
            badgeContent={ticket.unreadMessages}
            classes={{
              badge: classes.badgeStyle,
            }}
            style={{
              position: 'absolute',
              bottom: '0px', // Mantenha o bottom para ajustar a posição vertical, se necessário
              right: '15px', // Ajuste a quantidade de deslocamento para a direita aqui
            }}
          />
        </div>
        </ListItemAvatar>
        {/* {ticket.status === "pending" && (
          <ButtonWithSpinner
            color="primary"
            variant="contained"
            className={classes.acceptButton}
            size="small"
            loading={loading}
            onClick={(e) => handleAcepptTicket(ticket.id)}
          >
            {i18n.t("ticketsList.buttons.accept")}
          </ButtonWithSpinner>
        )} */}
        <ListItemText
          disableTypography
          primary={
            <>            
              {ticket.status === "pending" && (
                <ButtonWithSpinner
                  color="primary"
                  variant="contained"
                  className={classes.acceptButton}
                  size="small"
                  loading={loading}
                  onClick={(e) => handleAcepptTicket(ticket.id)}
                >
                  {i18n.t("ticketsList.buttons.accept")}
                </ButtonWithSpinner>
              )} 
              <span className={classes.contactNameWrapper}> 
                <Typography
                  noWrap
                  component="span"
                  variant="body2"
                  color="textPrimary"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  {renderTicketChannel(ticket)}
                  <span style={{ marginLeft: '4px', fontWeight: 'bold' }}>{ticket.contact.name}</span>
                </Typography>
              </span>
            </>
            
          }
          secondary={
            <>           
              <span className={classes.contactNameWrapper}>            
                <Typography
                  className={classes.contactLastMessage}
                  noWrap
                  component="span"
                  variant="body2"
                  color="textSecondary"
                > 
                  {["composing", "recording"].includes(ticket?.presence) ? (
                      <span className={classes.presence}>
                        {i18n.t(`presence.${ticket.presence}`)}
                      </span>
                    ) : (
                    <>
                      {ticket.lastMessage ? (
                        <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </>
                  )}
                   



                  {/* aqui é a parte da conexão, atendente e fila */}
                  <span className={classes.secondaryContentSecond} >

                    {ticket.whatsappId && (
                      <Tooltip title={`Conexão do ticket`}>
                        <Badge
                          className={classes.Radiusdot}
                          badgeContent={(ticket?.whatsapp?.name)?.toUpperCase()}
                          style={{
                            backgroundColor: "#7d79f2",
                            height: 18,
                            padding: 5,
                            position: "inherit",
                            borderRadius: 7,
                            color: "white",
                            top: -6,
                            marginRight: 3
                          }}
                        />
                      </Tooltip>
                    )}

                    {ticket.userId && (
                      <Tooltip title={`Atendente do ticket`}>
                        <Badge
                          className={classes.Radiusdot}
                          style={{
                            backgroundColor: stringToColor(ticket?.user?.name) || "#7C7C7C",
                            height: 18,
                            padding: 5,
                            position: "inherit",
                            borderRadius: 7,
                            color: "white",
                            top: -6,
                            marginRight: 3,
                            whiteSpace: "nowrap",
                          }}
                          badgeContent={`${ticket.user?.name.toUpperCase()}`}
                        //color="primary"
                        />
                      </Tooltip>
                    )}

                    {ticket.queue?.name !== null && (
                      <Tooltip title={`Fila do ticket`}>
                        <Badge
                          className={classes.Radiusdot}
                          style={{
                            backgroundColor: ticket.queue?.color || "#7C7C7C",
                            height: 18,
                            padding: 5,
                            position: "inherit",
                            borderRadius: 7,
                            color: "white",
                            top: -6,
                            marginRight: 3,
                            whiteSpace: "nowrap",
                          }}
                          badgeContent={`${ticket.queue?.name.toUpperCase() || "SEM FILA"}`}
                        //color="primary"
                        />
                      </Tooltip>
                    )}
                  </span>



                  {/* Aqui é a parte das tags */}
                  <span style={{ paddingTop: "2px" }} className={classes.secondaryContentSecondTag} >
                    {/* <div style={{display: 'flex', flexDirection: 'row', gap: '0px'}}> 
                      {ticket?.tags?.length > 0 && (
                        <AiOutlineTags size={20} style={{ marginRight: '2px', marginTop: '5px' }} />
                      )} */}
                      {
                        ticket.tags?.map((tag) => {
                          return (
                            <ContactTag tag={tag} key={tag.id} />
                          );
                        })
                      }
                    {/* </div>  */}
                  </span>

                </Typography>

                {/* <Badge
                  className={classes.newMessagesCount}
                  badgeContent={ticket.unreadMessages}
                  classes={{
                    badge: classes.badgeStyle,
                  }}
                /> */}
              </span>
            </>
          }
        />
        <ListItemSecondaryAction>
          {ticket.status === "closed" && (
            <Badge
              className={classes.closedBadge}
              badgeContent={i18n.t("tickets.tabs.closed.single")}
              color="primary"
            />
          )}
          {ticket.lastMessage && (
            <>
              <Typography
                className={classes.lastMessageTime}
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {renderizarData(ticket)}
              </Typography>
              <br />
              <Box className={classes.ticketInfo}>{renderTicketInfo()}</Box>
            </>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

export default TicketListItemCustom;
