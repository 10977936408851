import { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import useCompanies from "../hooks/useCompanies";
import moment from "moment"; 
import toastError from "../errors/toastError";
import { AuthContext } from "../context/Auth/AuthContext"; 
import { SocketContext } from "../context/Socket/SocketContext";

const PageChangeHandler = () => {
  const location = useLocation();
  const history = useHistory();
  const { find } = useCompanies(); 
  const [idCompany, setIdCompany] = useState(null);
  const { user: loggedInUser, handleLogout } = useContext(AuthContext);

  const socketManager = useContext(SocketContext);
  
  useEffect(() => {
    async function fetchData() {
      const companyId = localStorage.getItem("companyId");
      
      if (!loggedInUser || companyId === '')
        return;
      
      const socket = socketManager.GetSocket(companyId);
      setIdCompany(companyId);
 
      // Logoff automaticamente caso a empresa seja desabilitada ou excluída;
      // Se a dueDate esteja vencida, sempre exibir a guia de fatura se for admin,
      // se for user logoff
      const handleCompanyChange = async data => {
        if (data.action === "update" || data.action === "delete")
          await actionForCompany(
            location.pathname,
            loggedInUser.profile,
            data.company,
            data.action === "delete"
          );
        // se for user, msg requer atenção do admin e logoff
        // se for admin, redirecionar para invoices
      };

      socket.on(`company-${companyId}-changed`, handleCompanyChange);

      let companyData;
      if (companyId)
        companyData = await find(companyId);
      
      if (companyData) {
        await actionForCompany(
          location.pathname,
          loggedInUser.profile,
          companyData,
          false
        );
      }

      return () => {
        socket.off(`company-${companyId}-changed`, handleCompanyChange);
        socket.disconnect();
      };
    }

    fetchData();
    // eslint-disable-next-line
  }, [location, socketManager]);

  const actionForCompany = async (pathname, profile, company, forceLogoff) => {
    if (!company || !idCompany || pathname.startsWith("/invoices")) return;

    if (Number(company.id) === Number(idCompany)) {
      if (company?.dueDate) {
        moment.locale("pt-br");
        const vencimento = moment(company.dueDate).format("DD/MM/yyyy"); 
         
        // // Corrige o problema de bloquear no mesmo dia que vence a assinatura 
        const due = moment().isAfter(moment(company.dueDate).add(1, 'day'));       
        
        if (due) {
          if (profile === "admin") {
            toastError(
              `Ooops! Sua assinatura venceu em ${vencimento}. Renove para continuar utilizando a plataforma! `
            );

            // Redireciona para invoices
            if (
              pathname.startsWith("/tickets") ||
              pathname.startsWith("/connections") ||
              pathname.startsWith("/campaigns")
            ) {
              history.push("/invoices");
              return;
            }
          } else if (profile === "user") {
            toastError(
              `Ooops! A plataforma requer atenção do administrador da ${company.name}. `
            );
            await handleLogout();
            return;
          }
        }
      }

      if (company.status !== true) {
        toastError(
          `Ooops! A ${company.name} está desativada, fale com suporte técnico. `
        );
        await handleLogout();
        return;
      }
    }

    if (forceLogoff) await handleLogout();
  };

  return null;
};

export default PageChangeHandler;
