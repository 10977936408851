import React, { useState, useEffect, useRef } from "react";
// import * as Yup from "yup";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  // MenuItem,
  // Menu,
  IconButton,
  Tooltip, 
  useMediaQuery, 
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import ItemSelect from "../ItemSelect";
import { Autorenew, FileCopy, //MoreVert 
} from "@material-ui/icons";
import useSettings from "../../hooks/useSettings";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useContext } from "react";
import usePlans from "../../hooks/usePlans";
import HelperTextWithChips from "../HelperTextWithChips";
import useQueues from "../../hooks/useQueues";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

// const SessionSchema = Yup.object().shape({
//   name: Yup.string()
//     .min(2, "Muito curto!")
//     .max(50, "Muito longo!")
//     .required("Obrigatório")
// });

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();

  const messageInputRef = useRef();
  const initialState = {
    name: "",
    greetingMessage: "",
    complationMessage: "",
    outOfHoursMessage: "",
    ratingMessage: "",
    isDefault: false,
    token: "",
    provider: "beta",
    timeToTransfer: "", 
    promptId: "",
    expiresInactiveMessage: "",
    expiresTicket: 0,
    maxUseBotQueues: 3
  };
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]); 
  const [itemsQueues, setItemsQueues] = useState([]); 
  const [selectedQueueId, setSelectedQueueId] = useState(null);
  const [queues, setQueues] = useState([]);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [menuOpen, setMenuOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [useApi, setUseApi] = useState(false);
  const [useRating, setUseRating] = useState(false);
  const [useSchedule, setUseSchedule] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [useIntegration, setUseIntegration] = useState(false);
  const [selectedPromptId, setSelectedPromptId] = useState(null); 
  const [showMessageInative, setShowMessageInative] = useState(false);

  const { user } = useContext(AuthContext);  
  const { findValuesOf } = useSettings();  
  const { finder: listPlans } = usePlans();
  const { findAll: findAllQueues } = useQueues();
  
  const isMobile = useMediaQuery('(max-width: 600px)');  

  const handleFieldChange = (fieldName, value) => {
    if (fieldName === 'expiresTicket')
      setShowMessageInative(value > 0);

    setWhatsApp(prevState => ({
      ...prevState,
      [fieldName]: value
    }));
  };
 
  useEffect(() => { 
    const fetchSession = async () => { 
      const list = await findAllQueues(); 
      setQueues(list); 

      const values = await findValuesOf(['useApi', 'userRating', 'scheduleType']);  
      setUseApi(values.useApi === 'true');
      setUseRating(values.userRating === 'true' || values.userRating === 'enabled');
      setUseSchedule(values.scheduleType === 'company'); 

      const planData = await listPlans(user.company.planId);
      setUseIntegration(planData.useIntegration || false);      
    };
    fetchSession();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (useIntegration && useIntegration === true) {
          const { data } = await api.get("/queueIntegration");
          const itemsIntegrationData = [
            { value: 0, name: "Nenhuma" },
            ...(data.queueIntegrations || [])
              .filter(integration => integration.type !== "")
              .map(integration => ({ value: integration.id, name: integration.name })),
          ];                      
          setIntegrations(itemsIntegrationData);
        }
      } catch (err) {
        toastError(err);
      }
    })();
  }, [useIntegration]);
  
  useEffect(() => {
    const fetchSession = async () => {
      setIsSubmitting(false);
      setSelectedQueueIds([]); 
      if (!whatsAppId) return;
      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}?session=0`);
        setWhatsApp(data);
        const whatsQueueIds = data.queues?.map(queue => queue.id);
        setSelectedQueueIds(whatsQueueIds); 
        setSelectedQueueId(data.transferQueueId || null);
        setSelectedPromptId(data.promptId || null);
        setShowMessageInative(data.expiresTicket > 0);

        const itemsQueuesData = [
          { value: 0, name: "Nenhuma" },
          ...data.queues?.map((queue) => ({ value: queue.id, name: queue.name })),
        ];   
        setItemsQueues(itemsQueuesData);        

      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  const handleSaveWhatsApp = async values => { 
    let idQueueTransf = selectedQueueId;
    let idIntegration = selectedPromptId;

    if (values.timeToTransfer === '' || 
      values.timeToTransfer === '0' || 
      values.timeToTransfer === 0 || 
      values.timeToTransfer === null) 
    {
      idQueueTransf = null;
      values.timeToTransfer = null;
    } else 
    if (!(idQueueTransf > 0) || idQueueTransf === null) {
      toast.warn('Informe a fila de transferência automática!');
      return;
    }
    if (!(idIntegration > 0))
      idIntegration = null;
    
    setIsSubmitting(true);
 
    const queueIds = selectedQueueIds.length ? selectedQueueIds : [];
    const whatsappData = { 
      ...values, 
      queueIds,
      transferQueueId: idQueueTransf,
      promptId: idIntegration,
    };

    delete whatsappData["queues"];
    delete whatsappData["session"]; 
    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        await api.post("/whatsapp", whatsappData);
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedQueueId(null);
    setSelectedPromptId(null);
    setWhatsApp(initialState);
  };

  const handleChipClick = async (chipValue, elementId) => { 
    if (!chipValue || !elementId) return;
  
    const textField = document.getElementById(elementId);
    
    if (textField) { 
      const cursorPosition = textField.selectionStart;
      const start = textField.value.slice(0, cursorPosition);
      const end = textField.value.slice(cursorPosition);   

      handleFieldChange(elementId, `${start}${chipValue}${end}`);

      textField.setSelectionRange(cursorPosition + chipValue.length, cursorPosition + chipValue.length);       
    }   
  };  

  // const handleOptionsMenu = event => {
  //   setAnchorEl(event.currentTarget);
  //   setMenuOpen(true);
  // };

  const handleCloseOptionsMenu = () => {
    // setMenuOpen(false);
  };

  function generateRandomToken(length, setInField) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let token = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters.charAt(randomIndex);
    }
    if (setInField === true)
      handleFieldChange("token", token);
    return token;
  }

  const handleMessagesDefault = () => {
    handleCloseOptionsMenu();

    // if (whatsApp.name.trim() === "") {
    //   toast.warn("Em nome, defina o nome da sua empresa de modo temporário!");
    //   return;
    // }
 
    const greetingMessage = `{Olá|Oi|Opa|Opa jóia?|Olá, tudo bem?|Oi, tudo jóia?} {{ms}} {{name}}! 

Bem vindo(a) à *${localStorage.getItem("companyName").trim()}*!

Por favor, *informe o departamento* correspondente que deseja atendimento.`;
    const complationMessage = `Atendimento *finalizado*.
O número do protocolo é: *{{protocol}}*

A *${localStorage.getItem("companyName").trim()}* agradece seu contato!`;
    const outOfHoursMessage = `Estamos *fora do horário* de expediente, em breve *retornaremos* sua mensagem!`;
    const ratingMessage = `"Por favor, *avalie nosso atendimento*. Sua opinião é importante para nós!"`;
    const expiresInactiveMessage = 
    `Como já faz um tempinho que não nos falamos, este atendimento está sendo finalizado.
Caso queira realizar outra solicitação, pode falar comigo a qualquer momento. Até mais! 😊`;

    handleFieldChange("greetingMessage", greetingMessage);
    handleFieldChange("complationMessage", complationMessage);
    handleFieldChange("outOfHoursMessage", outOfHoursMessage);
    handleFieldChange("ratingMessage", ratingMessage);
    handleFieldChange("expiresInactiveMessage", expiresInactiveMessage);

    if (whatsApp.token === "" && useApi) {
      const token = generateRandomToken(64, false);
      handleFieldChange("token", token);
    }
    if (!useApi) {
      handleFieldChange("token", "");
    }
 
    handleFieldChange("name", whatsApp.name.replaceAll("*", ""));

    toast.info("Mensagens definidas com sucesso!");
  };

  const handleSubmit = async event => {
    event.preventDefault();
    
    if (whatsApp.timeToTransfer > 0 && selectedQueueId && selectedQueueIds.length > 0 && !selectedQueueIds.includes(selectedQueueId)) {
      toast.warn(
        "A fila para auto-transferência precisa está entre as filas que a conexão irá atender!",
        {
          autoClose: 6000,
        }
      );
      return;
    }

    if (whatsApp.expiresTicket > 0 && whatsApp.expiresInactiveMessage === '') {
      toast.warn(
        "É necessário informar a mensagem de auto-fechamento dos tickets por falta de atividade!",
        {
          autoClose: 6000,
        }
      );
      return;
    }

    if (!(whatsApp.timeToTransfer > 0))
      setSelectedQueueId(null);
    if (!(whatsApp.promptId > 0))
      setSelectedPromptId(null);

    if (whatsApp.name.trim() === "") {
      toast.warn(
        "Campo nome é obrigatório. Algo que identifique seu número de WhatsApp."
      );
      return;
    }

    try {
      await handleSaveWhatsApp(whatsApp);
    } catch (error) {
      toastError(error);
    }
  };

  const copiarConteudo = () => {
    const campoTexto = document.getElementById("token");  
  
    campoTexto.select();
    document.execCommand("copy");
  
    navigator.clipboard.writeText(campoTexto.value)
      .then(() => {
        toast.info('Token Bearer copiado com sucesso!');
      })
      .catch((err) => {
        toastError('Erro ao copiar o Token Bearer: '+ err);
      });
  
    // Desselecione o texto após a cópia (opcional)
    window.getSelection().removeAllRanges();
  };  

  const handleQueueSelectChange = (selectedIds) => {
    setSelectedQueueId(selectedIds);    
  };
 
  const handleIntegrationChange = (selectedId) => {
    setSelectedPromptId(selectedId);
  };

  const handleQueueChange = (selectedIds) => {
    setSelectedQueueIds(selectedIds);
    
    setItemsQueues([
      { value: 0, name: "Nenhuma" },  
      ...queues
        .filter(queue => selectedIds.includes(queue.id))  
        .map(queue => ({
          value: queue.id,
          name: queue.name
        }))
    ]); 
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <DialogTitle>
            {whatsAppId
              ? i18n.t("whatsappModal.title.edit")
              : i18n.t("whatsappModal.title.add")}
          </DialogTitle>

          {/* <IconButton
            onClick={handleOptionsMenu}
            style={{ marginLeft: "auto" }}
            disabled={isSubmitting}
          >
            <MoreVert />
          </IconButton> */}
        </div>
        {/* <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={menuOpen}
          onClose={handleCloseOptionsMenu}
        >
          <MenuItem onClick={handleMessagesDefault}>
            Gerar mensagens padrão
          </MenuItem>
        </Menu> */}

        <form onSubmit={handleSubmit} className={classes.mainPaper}>
          <DialogContent dividers className={classes.mainPaper} >
            <div className={classes.multFieldLine}>
              <Grid spacing={2} container>
                <Grid item>
                  <TextField
                    label={i18n.t("whatsappModal.form.name")}
                    autoFocus
                    name="name"
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    value={whatsApp.name}
                    onChange={event =>
                      handleFieldChange("name", event.target.value)
                    }
                    helperText="Algo que faça lembrar o número dessa conexão."
                  />
                </Grid>
                <Grid style={{ paddingTop: 15 }} item>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        name="isDefault"
                        disabled={isSubmitting}
                        checked={whatsApp.isDefault}
                        onChange={event =>
                          handleFieldChange("isDefault", event.target.checked)
                        }
                      />
                    }
                    label={i18n.t("whatsappModal.form.default")}
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <TextField
                label={i18n.t("queueModal.form.greetingMessage")} 
                multiline
                rows={5}
                fullWidth
                disabled={isSubmitting}
                inputRef={messageInputRef}
                id="greetingMessage"
                name="greetingMessage"
                variant="outlined"
                margin="dense"
                value={whatsApp.greetingMessage}
                onChange={event =>
                  handleFieldChange("greetingMessage", event.target.value)
                }
                helperText={
                  <HelperTextWithChips onChipClick={(chipValue) => 
                      handleChipClick(chipValue, 'greetingMessage')
                    } 
                  />
                }
              />
            </div>
            <div>
              <TextField
                label={i18n.t("queueModal.form.complationMessage")} 
                multiline
                rows={4}
                fullWidth
                disabled={isSubmitting}
                inputRef={messageInputRef}
                id="complationMessage"
                name="complationMessage"
                variant="outlined"
                margin="dense"
                value={whatsApp.complationMessage}
                onChange={event =>
                  handleFieldChange("complationMessage", event.target.value)
                }
                helperText={
                  <HelperTextWithChips onChipClick={(chipValue) => 
                      handleChipClick(chipValue, 'complationMessage')
                    } 
                  />
                }
              />
            </div>
            {useSchedule && (
              <div>
                <TextField
                  label={i18n.t("queueModal.form.outOfHoursMessage")} 
                  multiline
                  rows={3}
                  fullWidth
                  disabled={isSubmitting}
                  inputRef={messageInputRef}
                  id="outOfHoursMessage"
                  name="outOfHoursMessage"
                  variant="outlined"
                  margin="dense"
                  value={whatsApp.outOfHoursMessage}
                  onChange={event =>
                    handleFieldChange("outOfHoursMessage", event.target.value)
                  }
                  helperText={
                    <HelperTextWithChips onChipClick={(chipValue) => 
                        handleChipClick(chipValue, 'outOfHoursMessage')
                      } 
                    />
                  }
                />
              </div>
            )}
            {useRating && (
              <div>
                <TextField
                  label={i18n.t("queueModal.form.ratingMessage")} 
                  multiline
                  rows={2}
                  fullWidth
                  disabled={isSubmitting}
                  name="ratingMessage"
                  variant="outlined"
                  margin="dense"
                  value={whatsApp.ratingMessage}
                  onChange={event =>
                    handleFieldChange("ratingMessage", event.target.value)
                  }
                /> 
              </div>
            )}
            <QueueSelect
              disabled={isSubmitting}
              selectedQueueIds={selectedQueueIds}              
              // onChange={selectedIds => setSelectedQueueIds(selectedIds)} 
              onChange={handleQueueChange} 
              title={"Filas que esta conexão irá atender"}
            />
            <Grid xs={12} sm={12} md={12} item> 
              <ItemSelect 
                fullWidth
                disabled={isSubmitting}
                title={'IA de Inteligência de atendimento | Integração'}
                selectedValues={selectedPromptId || "0"}
                items={integrations} 
                onChange={handleIntegrationChange} 
                labelWidth={335} 
              />   
            </Grid>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Grid container spacing={2}>
                <Grid item sm={6} >
                  <TextField
                    fullWidth
                    type="number" 
                    label="Auto-Transferir após x (minutos)"
                    placeholder="Auto-Transferir após x (minutos)" 
                    name="timeToTransfer" 
                    disabled={isSubmitting}
                    value={whatsApp.timeToTransfer ?? ""}
                    onChange={event =>
                      handleFieldChange("timeToTransfer", event.target.value)
                    }
                    variant="outlined"
                    margin="dense" 
                  />
                </Grid>

                <Grid item sm={6}> 
                  <ItemSelect 
                    fullWidth
                    disabled={isSubmitting}
                    title={'Fila de Auto-Transferência'}
                    selectedValues={selectedQueueId || '0'}
                    items={itemsQueues}  
                    onChange={handleQueueSelectChange} 
                    minWidth={!isMobile ? 250 : 200}
                    labelWidth={190} 
                  /> 
                </Grid>
  
              </Grid>				
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Grid container spacing={2}>
                <Grid item sm={6} >
                  <TextField
                    fullWidth
                    type="number" 
                    label="Enviar chatbot x vezes"
                    placeholder="Enviar chatbot x vezes" 
                    name="maxUseBotQueues" 
                    disabled={isSubmitting}
                    value={whatsApp.maxUseBotQueues ?? ""}
                    onChange={event =>
                      handleFieldChange("maxUseBotQueues", event.target.value)
                    }
                    variant="outlined"
                    margin="dense" 
                  />
                </Grid>

                <Grid item sm={6} >
                  <TextField
                    fullWidth
                    type="number" 
                    label="Auto-Encerrar Ticket após x (minutos) sem atividade"
                    placeholder="Auto-Encerrar Ticket após x (minutos) sem atividade" 
                    name="expiresTicket" 
                    disabled={isSubmitting}
                    value={whatsApp.expiresTicket ?? "0"}
                    onChange={event => {
                      let value = Math.max(0, Math.floor(Number(event.target.value) || 0));  
                      handleFieldChange("expiresTicket", value);
                    }}
                    inputProps={{
                      min: 0,
                      step: 1 
                    }}
                    variant="outlined"
                    margin="dense" 
                  />
                </Grid>
              </Grid>				
            </div>

            {showMessageInative && (
              <div> 
                <TextField
                  label={i18n.t("queueModal.form.expiresInactiveMessage")}
                  multiline
                  rows={2}
                  fullWidth 
                  disabled={isSubmitting}
                  inputRef={messageInputRef}
                  name="expiresInactiveMessage"
                  variant="outlined"
                  margin="dense"
                  value={whatsApp.expiresInactiveMessage}
                  onChange={event =>
                    handleFieldChange("expiresInactiveMessage", event.target.value)
                  }
                /> 
              </div>
            )}

            {useApi && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label={i18n.t("queueModal.form.token")}
                  type="token"
                  fullWidth
                  id="token"
                  name="token"
                  variant="outlined"
                  margin="dense"
                  value={whatsApp.token}
                  onChange={event =>
                    handleFieldChange("token", event.target.value)
                  }
                  disabled
                />
                <Tooltip title="Gerar Token Bearer">
                  <IconButton onClick={() => generateRandomToken(64, true)}>
                    <Autorenew /> 
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copiar token">
                  <IconButton onClick={() => copiarConteudo()}>
                    <FileCopy /> 
                  </IconButton>              
                </Tooltip>
              </div>
            )}            
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              disabled={isSubmitting}
              variant="outlined"
            >
              {i18n.t("whatsappModal.buttons.cancel")}
            </Button>

            <Button 
              color="primary"
              disabled={isSubmitting}
              variant="outlined"
              className={classes.btnWrapper}
              onClick={handleMessagesDefault}
            >
              Gerar mensagens
            </Button>

            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              variant="contained"
              className={classes.btnWrapper}
              onClick={handleSubmit}
            >
              {whatsAppId
                ? i18n.t("whatsappModal.buttons.okEdit")
                : i18n.t("whatsappModal.buttons.okAdd")}
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
