import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { has, isArray } from "lodash";

import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError"; 
import moment from "moment";
import { SocketContext } from "../../context/Socket/SocketContext";

const useAuth = () => {
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({}); 

  const socketManager = useContext(SocketContext);

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
        setIsAuth(true);
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        const { data } = await api.post("/auth/refresh_token");
        if (data) {
          localStorage.setItem("token", JSON.stringify(data.token));
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
        }
        return api(originalRequest); 
      }

      if (error?.response?.status === 401) {
        console.log('Deu erro 401 no useAuth.js');
        localStorage.removeItem("preferredTheme");
        localStorage.removeItem("token");
        localStorage.removeItem("companyId");
        api.defaults.headers.Authorization = undefined;
        setIsAuth(false);
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const token = localStorage.getItem("token"); 
    (async () => {
      if (token) { 
        try {
          const { data } = await api.post("/auth/refresh_token");
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
          setIsAuth(true);
          setUser(data.user); 
        } catch (err) {
          console.log('Erro in useEffect refresh_token:', err);
          toastError(err);
        }
      }
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    if (companyId) {
      const socket = socketManager.GetSocket(companyId);

      socket.on(`company-${companyId}-user`, (data) => {
        if (data.action === "update" && data.user.id === user.id) {
          setUser(data.user);
        }
      });

      return () => {
        socket.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, socketManager]);

  const posLogin = async (data, impersonated) => { 
    const { user: { companyId, id, company } } = data;

    if (!data.user.status && !data.user.impersonated.status) {
      setLoading(false);
      toast.warn(`Ooops! Seu usuário foi desativado pelo administrador da ${data.user.company.name}. `, { autoClose: 7000 });
      return;
    }

    if (has(company, "settings") && isArray(company.settings)) {
      const setting = company.settings.find((s) => s.key === "campaignsEnabled");
      if (setting && setting.value === "true") {
        localStorage.setItem("cshow", null); //regra pra exibir campanhas
      }        
      const useApi = company.settings.find((s) => s.key === "useApi");
      if (useApi && useApi.value === "true") {
        localStorage.setItem("useApi", useApi.value); //regra pra exibir o uso da API
      }
    }

    moment.locale('pt-br');  
    const vencimento = moment(data.user.company.dueDate).format("DD/MM/yyyy");
    
    const diff = moment(data.user.company.dueDate).diff(moment(moment()).format());

    // Corrige o problema de bloquear no mesmo dia que vence a assinatura       
    const after = moment().isAfter(moment(data.user.company.dueDate).add(1, 'day')); 
    const dias = moment.duration(diff).asDays();

    let goTicketsPage = true;

    localStorage.setItem("token", JSON.stringify(data.token));
    localStorage.setItem("companyId", companyId);
    localStorage.setItem("companyName", company.name);
    localStorage.setItem("companyVat", company.vat);
    localStorage.setItem("companyPhone", company.phone);
    localStorage.setItem("companyEmail", company.email);
    localStorage.setItem("userId", id);
    localStorage.setItem("companyDueDate", vencimento);  
    localStorage.setItem("impersonatedCompany", data.user?.impersonated?.companyId);
    localStorage.setItem("impersonatedUserId", data.user?.impersonated?.id);

    api.defaults.headers.Authorization = `Bearer ${data.token}`;
    setUser(data.user);
    setIsAuth(true);
    toast.success(i18n.t("auth.toasts.success"));
    if (Math.round(dias) < 5 && Math.round(dias) > 0 && data.user.profile === 'admin') {
      toast.warn(`Sua assinatura vence em ${Math.round(dias)} ${Math.round(dias) === 1 ? 'dia' : 'dias'} `);          
    }
    if (after && data.user.profile === 'admin' && !data.user.impersonated.status) {
      toastError(`Ooops! Sua assinatura venceu em ${vencimento}.
      Renove para continuar utilizando a plataforma! `);     
      goTicketsPage = false;     
      history.push("/invoices");
    }
    if (after && data.user.profile !== 'admin' && !data.user.impersonated.status) {
      toastError(`Ooops! A plataforma requer atenção do administrador da ${data.user.company.name}. `);   
      goTicketsPage = false;    
      await handleLogout();
    }
    setLoading(false); 
    
    if (goTicketsPage && !impersonated)
      history.push("/tickets");
  }

  const handleLogin = async (userData) => {
    setLoading(true);

    try {
      const { data } = await api.post("/auth/login", userData);
      posLogin(data, false);
      setLoading(false); 
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleImpersonate = async (companyId) => {
    setLoading(true);

    // Detecta se esta acessando a empresa do cliente ou voltando para super 
    let userIdSuper = user.id;
    if (companyId === localStorage.getItem("impersonatedCompany")) {
      userIdSuper = localStorage.getItem("impersonatedUserId");
      companyId = localStorage.getItem("impersonatedCompany");
    }

    try {
      const { data } = await api.get(`/auth/impersonate/${companyId}/${userIdSuper}`);
      posLogin(data, true); 
      setLoading(false);
      window.location.reload(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setLoading(true);

    try {
      await api.delete("/auth/logout");
      setIsAuth(false);
      localStorage.removeItem("token"); // precisa ser nessa posição
      setUser({});
      localStorage.removeItem("preferredTheme");      
      localStorage.removeItem("companyId");
      localStorage.removeItem("companyName");
      localStorage.removeItem("companyVat");
      localStorage.removeItem("companyPhone");
      localStorage.removeItem("companyEmail");
      localStorage.removeItem("userId");
      localStorage.removeItem("cshow");
      localStorage.removeItem("useApi");
      localStorage.removeItem("impersonatedCompany");
      localStorage.removeItem("impersonatedUserId");
      api.defaults.headers.Authorization = undefined;
      setLoading(false); 
      history.push("/login");
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const getCurrentUserInfo = async () => {
    try {
      const { data } = await api.get("/auth/me");
      return data;
    } catch (err) {
      toastError(err);
    }
  };

  const getStatusAndDueDateAssignature = async () => {
    try {
      const { data } = await api.get("/auth/statusAndDueDateAssignature"); 
      return data.status;
    } catch (err) {
      toastError(err);
    }
  };

  return {
    isAuth,
    user, 
    loading,
    handleLogin,
    handleImpersonate,
    handleLogout,
    getCurrentUserInfo,
    getStatusAndDueDateAssignature,
  };
};

export default useAuth;
