import React, { useState, useEffect, useContext } from "react";
import {
  makeStyles,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableCell,
  TableRow,
  IconButton,
  Select,
  FormControlLabel,
  InputAdornment,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  useMediaQuery, 
} from "@material-ui/core";
import { Formik, Form, Field } from "formik"; 
import ButtonWithSpinner from "../ButtonWithSpinner";
import ConfirmationModal from "../ConfirmationModal";

import { Block, CheckCircle, Edit as EditIcon } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from '@material-ui/icons/Search';

import { toast } from "react-toastify";
import useCompanies from "../../hooks/useCompanies";
import usePlans from "../../hooks/usePlans";
import ModalUsers from "../ModalUsers";
import api from "../../services/api";
// eslint-disable-next-line
import { head, isArray, has } from "lodash";
import { useDate } from "../../hooks/useDate";
 
import moment from "moment";
import { i18n } from "../../translate/i18n";
import IOSSwitch from "../IOSSwitch";  
import { green, red } from "@material-ui/core/colors";
import { cpf, cnpj } from 'cpf-cnpj-validator';  
import ItemSelect from "../ItemSelect";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainPaper: {
    width: "100%",
    flex: 1,
    padding: theme.spacing(2),
  },
  fullWidth: {
    width: "100%",
  },
  tableContainer: {
    width: "100%",
    overflowX: "scroll",
    ...theme.scrollbarStyles,
  },
  textfield: {
    width: "100%",
  },
  textRight: {
    textAlign: "right",
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  control: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  buttonContainer: {
    textAlign: "right",
    padding: theme.spacing(1),
  },
}));

export function CompanyForm(props) {
  const { onSubmit, onDelete, onImpersonate, onCancel, initialValue, loading } = props;
  const classes = useStyles();
  const [companies, setCompanies] = useState([]); 
  const [plans, setPlans] = useState([]);
  const [modalUser, setModalUser] = useState(false);
  const [firstUser, setFirstUser] = useState({});   
  const [limitOfCampaignBy, setLimitOfCampaignBy] = useState("doNotLimit");
  const [numberOfCampaigns, setNumberOfCampaigns] = useState(1);
  const [numberOfGroupsCampaigns, setNumberOfGroupsCampaigns] = useState(1);
  const [
    numberOfContactsInGroupsCampaigns,
    setNumberOfContactsInGroupsCampaigns,
  ] = useState(1);  

  const [record, setRecord] = useState({
    name: "",
    vat: "",
    email: "",
    phone: "",
    planId: "",
    clientOf: "",
    status: true,
    isResale: false,
    useApi: false,
    useFacebook: false,
    useInstagram: false,
    campaignsEnabled: false,
    limitOfCampaignBy: "doNotLimit",
    numberOfCampaigns: 1,
    numberOfGroupsCampaigns: 1,
    numberOfContactsInGroupsCampaigns: 1,    
    dueDate: null,
    recurrence: "",
    ...initialValue,
  });

  const { list: listCompanies } = useCompanies();
  const { list: listPlans } = usePlans();

  const isMobile = useMediaQuery("(max-width: 600px)"); // Ajuste o valor conforme necessário

  // eslint-disable-next-line
  const itemsLimitCampaignBy = [
    { value: "doNotLimit", name: "Não limitar" },
    { value: "day", name: "Dia" },
    { value: "week", name: "Semana" },
    { value: "month", name: "Mês" },
    { value: "year", name: "Ano" },
  ];

  useEffect(() => {
    async function fetchData() {
      const list = await listPlans();
      setPlans(list);

      const companiesList = await listCompanies();
      setCompanies(companiesList);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  useEffect(() => {
    setRecord((prev) => {
      if (moment(initialValue.dueDate).isValid()) {
        initialValue.dueDate = moment(initialValue.dueDate).format("YYYY-MM-DD");
      }

      setLimitOfCampaignBy(initialValue.limitOfCampaignBy || "doNotLimit");
      setNumberOfCampaigns(initialValue.numberOfCampaigns || 1);
      setNumberOfGroupsCampaigns(initialValue.numberOfGroupsCampaigns || 1);
      setNumberOfContactsInGroupsCampaigns(
        initialValue.numberOfContactsInGroupsCampaigns || 1
      );

      return {
        ...prev,
        ...initialValue,
      };
    });
  }, [initialValue]);

  const handleSubmit = async (data) => { 
    if (data.vat && !validateVat(data.vat)) {
      toast.error("CPF/CNPJ inválido, verifique!");
      return;
    } 

    if (!data.dueDate || !moment(data.dueDate).isValid()) 
      data.dueDate = null;

    if (!data.clientOf || data.clientOf === "")
      data.clientOf = null; 
      
    data.limitOfCampaignBy = limitOfCampaignBy;
    data.numberOfCampaigns = numberOfCampaigns;
    data.numberOfGroupsCampaigns = numberOfGroupsCampaigns;
    data.numberOfContactsInGroupsCampaigns = numberOfContactsInGroupsCampaigns;

    onSubmit(data);
    setRecord({ ...initialValue, dueDate: null });
  };

  const handleOpenModalUsers = async () => {
    try {
      const { data } = await api.get("/users/list", {
        params: {
          companyId: initialValue.id,
        },
      });
      if (isArray(data) && data.length) {
        setFirstUser(head(data));
      }
      setModalUser(true);
    } catch (e) {
      toast.error(e);
    }
  };

  const handleCloseModalUsers = () => {
    setFirstUser({});
    setModalUser(false);
  };

  const incrementDueDate = () => {
    const data = { ...record };
    if (data.dueDate && moment(data.dueDate).isValid()) {
      switch (data.recurrence) {
        case "MENSAL":
          data.dueDate = moment(data.dueDate)
            .add(1, "month")
            .format("YYYY-MM-DD");
          break;
        case "BIMESTRAL":
          data.dueDate = moment(data.dueDate)
            .add(2, "month")
            .format("YYYY-MM-DD");
          break;
        case "TRIMESTRAL":
          data.dueDate = moment(data.dueDate)
            .add(3, "month")
            .format("YYYY-MM-DD");
          break;
        case "SEMESTRAL":
          data.dueDate = moment(data.dueDate)
            .add(6, "month")
            .format("YYYY-MM-DD");
          break;
        case "ANUAL":
          data.dueDate = moment(data.dueDate)
            .add(12, "month")
            .format("YYYY-MM-DD");
          break;
        default:
          break;
      }
    }
    setRecord(data);
  };

  function validateVat(vat) {
    if (!vat || typeof vat !== 'string') 
      return false;
      
    const cleanVat = vat.replace(/\D/g, '');  
    if (cleanVat.length !== 11 && cleanVat.length !== 14) 
      return false; 
  
    if (cleanVat === '00000000000' || cleanVat === '11111111111' || cleanVat === '22222222222' || 
        cleanVat === '33333333333' || cleanVat === '44444444444' || cleanVat === '55555555555' || 
        cleanVat === '66666666666' || cleanVat === '77777777777' || cleanVat === '88888888888' || 
        cleanVat === '99999999999') {
      return false;
    }
  
    if (cleanVat.length === 11 && !cpf.isValid(cleanVat)) 
      return false; 
  
    if (cleanVat.length === 14 && !cnpj.isValid(cleanVat)) 
      return false; 
  
    return true;
  }
 
  const formatValue = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
  
    if (cleanedValue.length <= 11) {
      if (cleanedValue.length <= 3) {
        return cleanedValue.replace(/(\d{1,3})/, '$1');
      } else if (cleanedValue.length <= 6) {
        return cleanedValue.replace(/(\d{1,3})(\d{1,3})/, '$1.$2');
      } else if (cleanedValue.length <= 9) {
        return cleanedValue.replace(/(\d{1,3})(\d{1,3})(\d{1,3})/, '$1.$2.$3');
      } else {
        return cleanedValue.replace(/(\d{1,3})(\d{1,3})(\d{1,3})(\d{1,2})/, '$1.$2.$3-$4');
      }
    } else if (cleanedValue.length > 11) {
      if (cleanedValue.length <= 2) {
        return cleanedValue.replace(/(\d{1,2})/, '$1');
      } else if (cleanedValue.length <= 5) {
        return cleanedValue.replace(/(\d{2})(\d{1,3})/, '$1.$2');
      } else if (cleanedValue.length <= 8) {
        return cleanedValue.replace(/(\d{2})(\d{1,3})(\d{1,3})/, '$1.$2.$3');
      } else if (cleanedValue.length <= 12) {
        return cleanedValue.replace(/(\d{2})(\d{1,3})(\d{1,3})(\d{1,4})/, '$1.$2.$3/$4');
      } else {
        return cleanedValue.replace(/(\d{2})(\d{1,3})(\d{1,3})(\d{1,4})(\d{1,2})/, '$1.$2.$3/$4-$5');
      }
    } else {
      return cleanedValue;
    }
  }
    
  const handleChangeVat = (event) => {
    const inputValue = event.target.value.replace(/\D/g, '');
    const maskedValue = formatValue(inputValue);
    setRecord((prev) => ({
      ...prev, 
      vat: maskedValue,
    }));
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const buscarDadosCnpj = async (value, loading) => { 
    loading = true;
    const inputValue = value.replace(/\D/g, ''); 
    if (inputValue.length === 11 && !validateVat(inputValue)) {
        toast.error("CPF inválido, verifique!");

    } else if (inputValue.length === 14 && validateVat(inputValue)) {
        // let CNPJ = require('consulta-cnpj-ws');
        // let cnpj = new CNPJ();            
        // cnpj.consultaCNPJ({cnpj: inputValue}).then(result => {
        //     const data = result;  
        //     setRecord((prev) => ({
        //       ...prev, 
        //       name: data.nome,
        //       email: data.email,
        //       phone: data.telefone.replace(/\D/g, ''),
        //     }));
        // })
        // .catch (error => {
        //     console.error(error);
        // });
    } else {
      if (inputValue.length > 1) {
        toast.error("CPF/CNPJ inválido, verifique!");
      } else {
        toast.error("Informe um CNPJ antes de consultar!");
      }
    } 
    loading = false;
  };
   
  const handleNumberFieldChange = (
    setter,
    currentValue,
    value,
    minValue = 1 
  ) => {
    let numericValue = value; 
    numericValue = parseInt(value, 10);

    if (isNaN(numericValue) || numericValue < minValue) {
      toast.warn(
        `O valor deste campo deve ser um número maior ou igual a ${minValue}.`
      );
      setter(currentValue);
      return;
    } 

    setter(numericValue);
  };

  return (
    <>
      <ModalUsers
        userId={firstUser.id}
        companyId={initialValue.id}
        open={modalUser}
        onClose={handleCloseModalUsers}
      />
      <Formik
        enableReinitialize
        className={classes.fullWidth}
        initialValues={record}
        onSubmit={(values, { resetForm }) =>
          setTimeout(() => {
            handleSubmit(values);
            resetForm();
          }, 500)
        }
      >
        {(values, setValues) => (
          <Form className={classes.fullWidth}>
            <Grid spacing={2} justifyContent="flex-end" container>              
              <Grid xs={12} sm={6} md={4} item>      
                <FormControl margin="dense" variant="outlined" fullWidth>
                  <Field
                    as={TextField}                    
                    label="CPF/CNPJ"
                    name="vat"
                    value={record.vat}
                    onChange={handleChangeVat} 
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                    required
                    inputMode="numeric"
                    pattern="[0-9]*" 
                    inputlabelprops={{shrink: true,}}
                    InputProps={{
                      maxLength: 18,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton 
                            aria-label="Consultar CNPJ"
                            onClick={() => buscarDadosCnpj(record.vat, loading)}
                            edge="end"
                          >
                            <SearchIcon titleAccess="Consultar CNPJ" />
                          </IconButton>
                        </InputAdornment>
                      ),                      
                    }}                 
                  /> 
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormControl margin="dense" variant="outlined" fullWidth>
                  <Field
                    as={TextField}
                    label="Nome"
                    name="name" 
                    onChange={(event) => setRecord({ ...record, name: event.target.value })}
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormControl margin="dense" variant="outlined" fullWidth>
                  <Field
                    as={TextField}
                    label="E-mail"
                    name="email" 
                    onChange={(event) => setRecord({ ...record, email: event.target.value })}                    
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={2} item>
                <Field
                  as={TextField}
                  label="Whatsapp"
                  name="phone" 
                  onChange={(event) => setRecord({ ...record, phone: event.target.value })}                     
                  variant="outlined"
                  className={classes.fullWidth}
                  margin="dense"
                  required
                  inputMode="numeric"
                  pattern="[0-9]*" 
                />
              </Grid>
              <Grid xs={12} sm={6} md={2} item>
                <FormControl margin="dense" variant="outlined" fullWidth>
                  <InputLabel htmlFor="plan-selection">Plano</InputLabel>
                  <Field
                    as={Select}
                    id="plan-selection"
                    label="Plano"
                    labelId="plan-selection-label"
                    name="planId"  
                    onChange={(event) => setRecord({ ...record, planId: event.target.value })}
                    margin="dense"
                    required
                  >
                    {plans.map((plan, key) => (
                      <MenuItem key={key} value={plan.id}>
                        {plan.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>

              <Grid xs={12} sm={6} md={2} item>
                <FormControl variant="outlined" fullWidth> 
                  <Field
                    as={TextField}
                    label="Vencimento"
                    type="date"
                    name="dueDate"
                    value={record?.dueDate || undefined}
                    onChange={(event) => setRecord({ ...record, dueDate: event.target.value })}
                    inputlabelprops={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={2} item>
                <FormControl margin="dense" variant="outlined" fullWidth>
                  <InputLabel htmlFor="recorrencia-selection">Recorrência</InputLabel>
                  <Field
                    as={Select}
                    label="Recorrência"
                    labelId="recorrencia-selection-label"
                    id="recurrence"
                    name="recurrence"    
                    onChange={(event) => setRecord({ ...record, recurrence: event.target.value })}                   
                    margin="dense"
                  >
                    <MenuItem value="MENSAL">Mensal</MenuItem>
                    <MenuItem value="BIMESTRAL">Bimestral</MenuItem>
                    <MenuItem value="TRIMESTRAL">Trimestral</MenuItem>
                    <MenuItem value="SEMESTRAL">Semestral</MenuItem>
                    <MenuItem value="ANUAL">Anual</MenuItem>
                  </Field>
                </FormControl>
              </Grid>

              <Grid xs={12} sm={6} md={4} item>
                <FormControl margin="dense" variant="outlined" fullWidth>
                  <InputLabel htmlFor="clientOf-selection">Representante</InputLabel>   

                  <Field
                    as={Select}
                    label="Representante"
                    name="clientOf"
                    value={record.clientOf === undefined ? '' : record.clientOf}
                    onChange={(event) => setRecord({ ...record, clientOf: event.target.value }) }
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense" 
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          style={{ marginRight: '8px' }}
                          aria-label="Limpar Representante"
                          onClick={() => setRecord({ ...record, clientOf: "" })}
                          edge="end"
                        >
                          <ClearIcon titleAccess="Limpar Representante" />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                  {companies
                    .filter((company) => company.id !== record.id && company.isResale === true)
                    .map((company, key) => (
                    <MenuItem key={key} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}                
                  </Field> 
                </FormControl>
              </Grid>

              <Grid xs={12} sm={6} md={2} item> 
                <FormControl className={classes.selectContainer}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={record.status === true} 
                        onChange={(event) => setRecord({ ...record, status: event.target.checked })}                           
                      />
                    }
                    label="Status"
                  /> 
                </FormControl>

              </Grid>              
              <Grid xs={12} sm={6} md={2} item> 
                <FormControl className={classes.selectContainer}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={record.isResale === true} 
                        onChange={(event) => setRecord({ ...record, isResale: event.target.checked })}                           
                      />
                    }
                    label="Revenda"
                  /> 
                </FormControl>

              </Grid>
              <Grid xs={12} sm={6} md={2} item> 
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={record.campaignsEnabled === true} 
                      onChange={(event) => setRecord({ ...record, campaignsEnabled: event.target.checked })}    
                    />
                  }
                  label="Campanhas"
                /> 

              </Grid>
              <Grid xs={12} sm={6} md={2} item> 
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={record.useApi === true} 
                      onChange={(event) => setRecord({ ...record, useApi: event.target.checked })}  
                    />
                  }
                  label="API Rest"
                /> 
              </Grid>
              <Grid xs={12} sm={6} md={2} item> 
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={record.useFacebook === true} 
                      onChange={(event) => setRecord({ ...record, useFacebook: event.target.checked })}  
                    />
                  }
                  label="Facebook"
                /> 
              </Grid>
              <Grid xs={12} sm={6} md={2} item> 
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={record.useInstagram === true} 
                      onChange={(event) => setRecord({ ...record, useInstagram: event.target.checked })}  
                    />
                  }
                  label="Instagram"
                /> 
              </Grid>
              
              {record.campaignsEnabled === true && (
                <Grid xs={12} item>
                  <Grid xs={12} sm={12} md={12} item>
                    <FormControl variant="outlined" fullWidth>
                      <Accordion
                        className={classes.root}
                        expanded={true}
                        elevation={9}
                      >
                        <AccordionSummary
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className={classes.heading}>
                            Parâmetros de campanhas
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          <Grid
                            spacing={3}
                            container
                            style={{ marginBottom: 10 }}
                          >
                            <Grid xs={12} sm={6} md={3} item>
                              <FormControl
                                fullWidth
                                className={classes.selectContainer}
                              >
                                <ItemSelect
                                  title={"Limitar campanha por"}
                                  labelWidth={170}
                                  selectedValues={
                                    limitOfCampaignBy || "doNotLimit"
                                  }
                                  items={itemsLimitCampaignBy}
                                  onChange={(value) =>
                                    setLimitOfCampaignBy(value)
                                  }
                                  minWidth={!isMobile ? 200 : 150}
                                />
                              </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6} md={3} item>
                              <FormControl
                                fullWidth
                                className={classes.selectContainer}
                              >
                                <TextField
                                  label="Qtde. de campanhas"
                                  type="number"
                                  disabled={limitOfCampaignBy === "doNotLimit"}
                                  inputProps={{ min: 1 }}
                                  value={numberOfCampaigns}
                                  variant="outlined"
                                  margin="dense"
                                  onChange={(e) =>
                                    handleNumberFieldChange(
                                      setNumberOfCampaigns,
                                      numberOfCampaigns,
                                      e.target.value
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6} md={3} item>
                              <FormControl
                                fullWidth
                                className={classes.selectContainer}
                              >
                                <TextField
                                  label="Qtde. de listas de contatos"
                                  type="number"
                                  disabled={limitOfCampaignBy === "doNotLimit"}
                                  inputProps={{ min: 1 }}
                                  value={numberOfGroupsCampaigns}
                                  variant="outlined"
                                  margin="dense"
                                  onChange={(e) =>
                                    handleNumberFieldChange(
                                      setNumberOfGroupsCampaigns,
                                      numberOfGroupsCampaigns,
                                      e.target.value
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6} md={3} item>
                              <FormControl
                                fullWidth
                                className={classes.selectContainer}
                              >
                                <TextField
                                  label="Contatos por lista"
                                  type="number"
                                  disabled={limitOfCampaignBy === "doNotLimit"}
                                  inputProps={{ min: 1 }}
                                  value={numberOfContactsInGroupsCampaigns}
                                  variant="outlined"
                                  margin="dense"
                                  onChange={(e) =>
                                    handleNumberFieldChange(
                                      setNumberOfContactsInGroupsCampaigns,
                                      numberOfContactsInGroupsCampaigns,
                                      e.target.value
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid> 
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              <Grid xs={12} item>
                <Grid justifyContent="flex-end" spacing={1} container>
                  <Grid xs={6} md={1} item>
                    <ButtonWithSpinner
                      className={classes.fullWidth}
                      style={{ marginTop: 7 }}
                      loading={loading}
                      onClick={() => onCancel()}
                      variant="contained"
                    >
                      Limpar
                    </ButtonWithSpinner>
                  </Grid>
                  {record.id !== undefined ? (
                    <>
                      <Grid xs={6} md={2} item>
                        <ButtonWithSpinner
                          className={classes.fullWidth}
                          style={{ marginTop: 7 }}
                          loading={loading}
                          onClick={() => onImpersonate(record)}
                          variant="contained" 
                        >
                          Ver cliente
                        </ButtonWithSpinner>
                      </Grid>
                      <Grid xs={6} md={1} item>
                        <ButtonWithSpinner
                          style={{ marginTop: 7 }}
                          className={classes.fullWidth}
                          loading={loading}
                          onClick={() => onDelete(record)}
                          variant="contained"
                          color="secondary"
                        >
                          Excluir
                        </ButtonWithSpinner>
                      </Grid>
                      <Grid xs={6} md={2} item>
                        <ButtonWithSpinner
                          style={{ marginTop: 7 }}
                          className={classes.fullWidth}
                          loading={loading}
                          onClick={() => incrementDueDate()}
                          variant="contained"
                          color="primary"
                        >
                          + Vencimento
                        </ButtonWithSpinner>
                      </Grid>
                      <Grid xs={6} md={1} item>
                        <ButtonWithSpinner
                          style={{ marginTop: 7 }}
                          className={classes.fullWidth}
                          loading={loading}
                          onClick={() => handleOpenModalUsers()}
                          variant="contained"
                          color="primary"
                        >
                          Usuário
                        </ButtonWithSpinner>
                      </Grid>
                    </>
                  ) : null}
                  <Grid xs={6} md={1} item>
                    <ButtonWithSpinner
                      className={classes.fullWidth}
                      style={{ marginTop: 7 }}
                      loading={loading}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Salvar
                    </ButtonWithSpinner>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export function CompaniesManagerGrid(props) {
  const { records, onSelect } = props;
  const classes = useStyles();
  const { dateToClient } = useDate();
 
  const renderPlan = (row) => {
    return row.planId !== null ? row.plan.name : "-";
  };
 
  const rowStyle = (record) => {
    if (moment(record.dueDate).isValid()) {
      const now = moment();
      const dueDate = moment(record.dueDate);
      const diff = dueDate.diff(now, "days");
      if (diff === 5) {
        return { backgroundColor: "#fffead" };
      }
      if (diff >= -3 && diff <= 4) {
        return { backgroundColor: "#f7cc8f" };
      }
      if (diff <= -4) {
        return { backgroundColor: "#fa8c8c" };
      }
    }
    return {};
  };

  return (
    <Paper className={classes.tableContainer}>
      <Table
        className={classes.fullWidth}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ width: "1%" }}>
              #
            </TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Nome</TableCell>
            {/* <TableCell align="left">Documento</TableCell> */}
            <TableCell align="left">E-mail</TableCell>
            <TableCell align="left">Whatsapp</TableCell>
            <TableCell align="left">Plano</TableCell>             
            <TableCell align="left">Cadastro</TableCell>
            <TableCell align="left">Vencimento</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((row, key) => (
            <TableRow style={rowStyle(row)} key={key}>
              <TableCell align="center" style={{ width: "1%" }}>
                <IconButton onClick={() => onSelect(row)} aria-label="delete">
                  <EditIcon 
                    titleAccess={`Id da empresa: ${row.id}`}
                  />
                </IconButton>
              </TableCell>
              {/* <TableCell align="left">{renderStatus(row)}</TableCell> */}
              <TableCell align="center" style={{ width: "1%" }}>
                {row.status ? (
                  <CheckCircle style={{ color: green[500] }} 
                    titleAccess={`Empresa ativa`}                    
                  />
                ) : (
                  <Block style={{ color: red[500] }} 
                    titleAccess={`Empresa inativa`}
                  />
                )}
              </TableCell>              
              <TableCell style={{ fontWeight: 'bold' }} align="left">{row.name || "-"}</TableCell>
              {/* <TableCell align="left">{row.vat || "-"}</TableCell> */}
              <TableCell align="left">{row.email || "-"}</TableCell>
              <TableCell align="left">{row.phone || "-"}</TableCell>
              <TableCell align="left">{renderPlan(row)}</TableCell>
              {/* <TableCell align="left">{renderCampaignsStatus(row)}</TableCell> */}              
              <TableCell align="left">{dateToClient(row.createdAt)}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="left">
                {dateToClient(row.dueDate)}
                <br />
                <span>{row.recurrence}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
          <TableCell align="center">
          </TableCell>
          <TableCell align="center">
          </TableCell>
              <TableCell align="left">
                  {i18n.t("table.totalRecords") + (records ? records?.length : 0)}
              </TableCell>
          </TableRow>
      </TableFooter>          
      </Table>
    </Paper>
  );
}

export default function CompaniesManager() {
  const classes = useStyles();
  const { list, save, update, remove } = useCompanies();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmImpersonateDialog, setShowConfirmImpersonateDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState({
    name: "",
    vat: "",
    email: "",
    phone: "",
    planId: "",
    clientOf: "",
    status: true,
    isResale: false,
    useApi: false,
    useFacebook: false,
    useInstagram: false,
    campaignsEnabled: false,
    limitOfCampaignBy: "doNotLimit",
    numberOfCampaigns: 1,
    numberOfGroupsCampaigns: 1,
    numberOfContactsInGroupsCampaigns: 1,
    dueDate: undefined,
    recurrence: "",
  });

  const { handleImpersonate } = useContext(AuthContext);

  useEffect(() => {
    loadPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPlans = async () => {
    setLoading(true);
    try {
      const companyList = await list();
      setRecords(companyList);
    } catch (e) {
      toast.error("Não foi possível carregar a lista de registros");
    }
    setLoading(false);
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      if (data.id !== undefined) {
        await update(data);
      } else {
        await save(data);
      } 
      await loadPlans();
      handleCancel();
      toast.success("Operação realizada com sucesso!");
    } catch (e) {
      toast.error(
        "Não foi possível realizar a operação. Verifique se já existe uma empresa com o mesmo nome ou se os campos foram preenchidos corretamente",
        {
          autoClose: 10000
        }
      );
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await remove(record.id);
      await loadPlans();
      handleCancel();
      toast.success("Operação realizada com sucesso!");
    } catch (e) {
      toast.error("Não foi possível realizar a operação");
    }
    setLoading(false);
  };

  const handleOpenDeleteDialog = () => {
    setShowConfirmDialog(true);
  };

  const onImpersonate = async () => {
    handleImpersonate(record.id);
  };

  const handleOpenImpersonateDialog = () => {
    setShowConfirmImpersonateDialog(true);
  };

  const handleCancel = () => {
    setRecord((prev) => ({
      ...prev,
      id: undefined,
      name: "",
      vat: "",
      email: "",
      phone: "",
      planId: "",
      clientOf: "",
      status: true,
      isResale: false,
      useApi: false,
      useFacebook: false,
      useInstagram: false,
      campaignsEnabled: false,
      limitOfCampaignBy: "doNotLimit",
      numberOfCampaigns: 1,
      numberOfGroupsCampaigns: 1,
      numberOfContactsInGroupsCampaigns: 1,
      dueDate: undefined,
      recurrence: "",
    }));
  };

  const handleSelect = (data) => {
    let campaignsEnabled = false;
    let useApi = false;
    let useFacebook = false;
    let useInstagram = false; 

    let limitOfCampaignBy = "doNotLimit";
    let numberOfCampaigns = 1;
    let numberOfGroupsCampaigns = 1;
    let numberOfContactsInGroupsCampaigns = 1;    

    // settingCampaigns
    const settingCampaigns = data.settings.find(
      (s) => s.key.indexOf("campaignsEnabled") > -1
    );
    if (settingCampaigns) 
      campaignsEnabled = settingCampaigns.value === "true" || settingCampaigns.value === "enabled";

    // settingLimitOfCampaignBy
    const settingLimitOfCampaignBy = data.settings.find(
      (s) => s.key.indexOf("limitOfCampaignBy") > -1
    );
    if (settingLimitOfCampaignBy)
      limitOfCampaignBy = settingLimitOfCampaignBy.value;

    // settingNumberOfCampaigns
    const settingNumberOfCampaigns = data.settings.find(
      (s) => s.key.indexOf("numberOfCampaigns") > -1
    );
    if (settingNumberOfCampaigns)
      numberOfCampaigns = settingNumberOfCampaigns.value;

    // settingNumberOfGroupsCampaigns
    const settingNumberOfGroupsCampaigns = data.settings.find(
      (s) => s.key.indexOf("numberOfGroupsCampaigns") > -1
    );
    if (settingNumberOfGroupsCampaigns)
      numberOfGroupsCampaigns = settingNumberOfGroupsCampaigns.value;

    // settingNumberOfContactsInGroupsCampaigns
    const settingNumberOfContactsInGroupsCampaigns = data.settings.find(
      (s) => s.key.indexOf("numberOfContactsInGroupsCampaigns") > -1
    );
    if (settingNumberOfContactsInGroupsCampaigns)
      numberOfContactsInGroupsCampaigns =
        settingNumberOfContactsInGroupsCampaigns.value;

    // settingUseApi
    const settingUseApi = data.settings.find(
      (s) => s.key.indexOf("useApi") > -1
    );
    if (settingUseApi) 
      useApi = settingUseApi.value === "true" || settingUseApi.value === "enabled";

    // settingFacebook
    const settingFacebook = data.settings.find(
      (s) => s.key.indexOf("useFacebook") > -1
    );
    if (settingFacebook) 
      useFacebook = settingFacebook.value === "true" || settingFacebook.value === "enabled";

    // settingInstagram
    const settingInstagram = data.settings.find(
      (s) => s.key.indexOf("useInstagram") > -1
    );
    if (settingInstagram) 
      useInstagram = settingInstagram.value === "true" || settingInstagram.value === "enabled";
    
    setRecord((prev) => ({
      ...prev,
      id: data.id,
      name: data.name || "",
      vat: data.vat || "",
      phone: data.phone || "",
      email: data.email || "",
      planId: data.planId || "",
      clientOf: data.clientOf || "",
      status: data.status === false ? false : true,
      isResale: data.isResale === false ? false : true,
      useApi, //: data.settings.useApi === false ? false : true,
      useFacebook, //: data.settings.useFacebook === false ? false : true,
      useInstagram, //: data.settings.useInstagram === false ? false : true,
      campaignsEnabled, //: data.settings.campaignsEnabled === false ? false : true,
      limitOfCampaignBy,
      numberOfCampaigns,
      numberOfGroupsCampaigns,
      numberOfContactsInGroupsCampaigns,
      dueDate: data.dueDate || null,
      recurrence: data.recurrence || "",
    }));
  };

  return (
    <Paper className={classes.mainPaper} elevation={0}>
      <Grid spacing={2} container>
        <Grid xs={12} item>
          <CompanyForm
            initialValue={record}
            onDelete={handleOpenDeleteDialog}
            onImpersonate={handleOpenImpersonateDialog}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} item>
          <CompaniesManagerGrid records={records} onSelect={handleSelect} />
        </Grid>
      </Grid>
      <ConfirmationModal
        title="Exclusão de registro"
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={() => handleDelete()}
      >
        Deseja realmente excluir esse registro?
      </ConfirmationModal>
      <ConfirmationModal
        title="Acessar como cliente"
        open={showConfirmImpersonateDialog}
        onClose={() => setShowConfirmImpersonateDialog(false)}
        onConfirm={() => onImpersonate()}
      >
        Deseja acessar o sistema como esta empresa?
      </ConfirmationModal>
    </Paper>
  );
}
