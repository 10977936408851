import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import TicketsManagerTabs from "../../components/TicketsManagerTabs/";
import Ticket from "../../components/Ticket/";

// eslint-disable-next-line
import { i18n } from "../../translate/i18n"; 
import logo from '../../assets/logo.png';

const useStyles = makeStyles(theme => ({
	chatContainer: {
		flex: 1,
		backgroundColor: theme.mode === 'dark' ? "" : "#eee",
		// padding: theme.spacing(4),
		height: `calc(100% - 48px)`,
		overflowY: "hidden",
	},

	chatPapper: {
		backgroundColor: "primary",
		display: "flex",
		height: "100%",
	},

	contactsWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflowY: "hidden",
	},
	messagesWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
	},
	welcomeMsg: {
		backgroundColor: theme.mode === 'dark' ? "" : "#eee",
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		height: "100%",
		textAlign: "center",
	},
}));

const TicketsCustom = (props) => {
	const classes = useStyles();
	const { ticketId } = useParams();
 
	return (
		<div className={classes.chatContainer}>
			<div className={classes.chatPapper}>
				<Grid container spacing={0}>
					<Grid item xs={4} className={classes.contactsWrapper}>
						<TicketsManagerTabs userViewTicketsWithoutDepartment={props.userViewTicketsWithoutDepartment}/>
					</Grid>
					<Grid item xs={8} className={classes.messagesWrapper}>
						{ticketId ? (
							<>
								<Ticket userViewTicketsWithoutDepartment={props.userViewTicketsWithoutDepartment}/>
							</>
						) : (							
							<Paper square variant="outlined" className={classes.welcomeMsg}>
								<div>
									<span>
										<img  
											src={logo} 
											// width="300" 
											// height="100" 
											alt="logo"
											onContextMenu={(e) => e.preventDefault()} // Impede o menu de contexto (botão direito)
											draggable="false" // Impede que a imagem seja arrastada
										/>
									</span><br />
									<span>{i18n.t("chat.noTicketMessage")}</span>
								</div>
							</Paper>
						)}
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default TicketsCustom;
