import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Add as AddIcon } from "@material-ui/icons";

import NewTicketModal from "../NewTicketModal";
import TicketsListCustom from "../TicketsListCustom";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Grid } from "@material-ui/core";
import { TagsFilter } from "../TagsFilter";
import { UsersFilter } from "../UsersFilter";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ToggleButton from "@material-ui/lab/ToggleButton";

const useStyles = makeStyles(theme => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  tabsHeader: {
    flex: "none",
    backgroundColor: theme.mode === "dark" ? "" : "#eee"
  },
  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8
  },
  tab: {
    minWidth: 70,
    width: "auto"
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // background: "#fafafa",
    padding: theme.spacing(0.5),
    background: theme.palette.optionsBackground,
    borderRadius: 5,
    borderColor: "#aaa",
    borderWidth: "1px",
    borderStyle: "solid",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: "200px"
  },

  serachInputWrapper: {
    flex: 1,
    // background: "grey", //"#fff",
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1)
  },

  searchIcon: {
    // color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center"
  },
  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30
  },

  badge: {
    right: "-10px"
  },
  show: {
    display: "block"
  },
  hide: {
    display: "none !important"
  },
  buttonTab: {
    height: 35,
    width: 35,
    border: "1px solid",
    borderColor: "#aaa",
    borderRadius: 8,
    marginRight: 8,
    "&:hover": {
      borderColor: theme.mode === "light" ? theme.palette.primary.main : "#FFF",
      border: "2px solid"
    }
  },
  button: {
    height: 30,
    width: 30,
    border: "1px solid",
    borderColor: "#aaa",
    borderRadius: 8,
    marginRight: 8,
    "&:hover": {
      borderColor: theme.mode === "light" ? theme.palette.primary.main : "#FFF",
      border: "2px solid"
    }
  },
  icon: {
    color: "#aaa",
    "&:hover": {
      color: theme.mode === "light" ? theme.palette.primary.main : "#FFF"
    }
  },
  tabsBadge: {
    top: "105%",
    right: "50%",
    transform: "translate(45%, 0)",
    whiteSpace: "nowrap",
    borderRadius: "12px",
    padding: "0 8px",
    backgroundColor:
      theme.mode === "light" ? theme.palette.primary.main : "#FFF",
    color: theme.mode === "light" ? "#FFF" : theme.palette.primary.main
  }
}));

const TicketsManagerTabs = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();

  const { user } = useContext(AuthContext);
  const { profile } = user; 

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user.queues.map(q => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [isHoveredNew, setIsHoveredNew] = useState(false);
  const [isHoveredAll, setIsHoveredAll] = useState(false);

  useEffect(() => {
    const fetchSession = async () => { 
      setShowAllTickets(profile === "admin");
    };
    fetchSession(); 
  }, [profile]);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  let searchTimeout;

  const handleSearch = e => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam('');
      // setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);

    if (newValue === 'open') {
      setSearchParam('');
      setSelectedTags([]);
      setSelectedUsers([]);
    }
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
    setSearchParam('');
    setSelectedTags([]);
    setSelectedUsers([]);
  };

  const applyPanelStyle = status => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleCloseOrOpenTicket = ticket => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleSelectedTags = selecteds => {
    const tags = selecteds.map(t => t.id);
    setSelectedTags(tags);
  };

  const handleSelectedUsers = selecteds => {
    const users = selecteds.map(t => t.id);
    setSelectedUsers(users);
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={ticket => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          // indicatorColor="primary"
          // textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            value={"open"} 
            icon={<MoveToInboxIcon className={classes.icon} />}
            label={i18n.t("tickets.tabs.open.title")}
          />
          <Tab
            value={"closed"} 
            icon={<CheckBoxIcon className={classes.icon} />}
            label={i18n.t("tickets.tabs.closed.title")}
          />
          <Tab
            value={"search"} 
            icon={<SearchIcon className={classes.icon} />}
            label={i18n.t("tickets.tabs.search.title")}
          />
        </Tabs>
      </Paper>
      <Paper
        square
        elevation={0}
        className={classes.ticketOptionsBox}
        style={{ height: "40px" }}
      >
        {tab === "search" ? (
          <div className={classes.serachInputWrapper}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase
              className={classes.searchInput}
              inputRef={searchInputRef}
              placeholder={i18n.t("tickets.search.placeholder")}
              type="search"
              onChange={handleSearch}
            />
          </div>
        ) : (
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Badge
                color="primary"
                invisible={!isHoveredNew}
                badgeContent={i18n.t("ticketsManager.buttons.newTicket")}
                classes={{ badge: classes.tabsBadge }}
              >
                <ToggleButton
                  value="newTicket"
                  onMouseEnter={() => setIsHoveredNew(true)}
                  onMouseLeave={() => setIsHoveredNew(false)}
                  className={classes.button}
                  onClick={() => {
                    setNewTicketModalOpen(true);
                  }}
                >
                  <AddIcon className={classes.icon} />
                </ToggleButton>
              </Badge>
              <Can
                role={user.profile}
                perform="tickets-manager:showall"
                yes={() => (
                  <>
                    <Badge
                      color="primary"
                      invisible={!isHoveredAll}
                      badgeContent={i18n.t("tickets.buttons.showAll")}
                      classes={{ badge: classes.tabsBadge }}
                    >
                      <ToggleButton
                        value="showAll"
                        onMouseEnter={() => setIsHoveredAll(true)}
                        onMouseLeave={() => setIsHoveredAll(false)}
                        className={classes.button}
                        selected={showAllTickets}
                        onChange={() =>
                          setShowAllTickets(prevState => !prevState)
                        }
                      >
                        {showAllTickets ? (
                          <VisibilityIcon className={classes.icon} />
                        ) : (
                          <VisibilityOffIcon className={classes.icon} />
                        )}
                      </ToggleButton>
                    </Badge>
                  </>
                )}
              />
            </Grid>
          </Grid>
        )}
        <TicketsQueueSelect
          style={{ marginLeft: 6 }}
          selectedQueueIds={selectedQueueIds}
          userQueues={user?.queues}
          onChange={values => setSelectedQueueIds(values)}
          height={30}
          borderRadius={8}
        />
      </Paper>
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          variant="fullWidth"
        >
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={openCount}
                color="primary"
              >
                {i18n.t("ticketsList.assignedHeader")}
              </Badge>
            }
            value={"open"}
            classes={{ root: classes.tab }}
          />
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={pendingCount}
                color="primary"
              >
                {i18n.t("ticketsList.pendingHeader")}
              </Badge>
            }
            value={"pending"}
            classes={{ root: classes.tab }}
          />
        </Tabs>
        <Paper className={classes.ticketsWrapper}>
          <TicketsListCustom
            status="open"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            updateCount={val => setOpenCount(val)}
            style={applyPanelStyle("open")}
            userViewTicketsWithoutDepartment={props.userViewTicketsWithoutDepartment}
          />
          <TicketsListCustom
            status="pending"
            selectedQueueIds={selectedQueueIds}
            updateCount={val => setPendingCount(val)}
            style={applyPanelStyle("pending")}
            onButtonClick={() => setTabOpen("open")}
            userViewTicketsWithoutDepartment={props.userViewTicketsWithoutDepartment}
          />
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
        <TicketsListCustom
          status="closed"
          showAll={showAllTickets}
          selectedQueueIds={selectedQueueIds}
          userViewTicketsWithoutDepartment={false} // vai filtrar pelo status
        />
      </TabPanel>
      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        <TagsFilter onFiltered={handleSelectedTags} />
        {profile === "admin" && (
          <UsersFilter onFiltered={handleSelectedUsers} />
        )}
        <TicketsListCustom
          status=""
          searchParam={searchParam}
          showAll={true}
          tags={selectedTags}
          users={selectedUsers}
          selectedQueueIds={selectedQueueIds}
          userViewTicketsWithoutDepartment={props.userViewTicketsWithoutDepartment ? props.userViewTicketsWithoutDepartment : false} // vai filtrar pelo status caso props.userViewTicketsWithoutDepartment = false
        />
      </TabPanel>
    </Paper>
  );
};

export default TicketsManagerTabs;
