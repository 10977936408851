import React, { useState, useEffect, useReducer, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItemCustom from "../TicketListItemCustom";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext"; 
// import useSettings from "../../hooks/useSettings";
import { SocketContext } from "../../context/Socket/SocketContext";

const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  ticketLists: {
    flex: 1,
    maxHeight: "100%",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    borderTop: "2px solid rgba(0, 0, 0, 0.12)",
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  
  // if (action && action.payload) {
  //   console.log('state '+ action.type +'\n', state);
  //   console.log('state '+ action.type, action.action);    
  // }

  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;
    newTickets.forEach((ticket) => {
      const ticketIndex = state.findIndex((t) => t.id === ticket.id);

      // console.log(action.type +' ticketIndex: '+ ticketIndex +' payload:\n', action.payload);

      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
        if (ticket.unreadMessages > 0) {
          state.unshift(state.splice(ticketIndex, 1)[0]);
        } 
      } else { 
        state.push(ticket);
      }
    });

    return [...state];
  }

  if (action.type === "RESET_UNREAD") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].unreadMessages = 0;
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET") {
    const ticket = action.payload; 
    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      state.unshift(ticket);
    } 
    return [...state];
  }

  if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
    const ticket = action.payload;
    let ticketIndex = -1;
    if (state && state.length > 0)
      ticketIndex = state.findIndex((t) => t.id === ticket.id); 
    
    // console.log(action.type +' ticketIndex: '+ ticketIndex +' payload:\n', action.payload);

    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
      state.unshift(state.splice(ticketIndex, 1)[0]);
      // console.log('ticket '+ ticket.id +' encontrado!');
    } else {
      state.unshift(ticket);
      // console.log('ticket '+ ticket.id +' NÃO encontrado!');
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_CONTACT") {
    const contact = action.payload;
    const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].contact = contact;
    }
    return [...state];
  }

  if (action.type === "UPDATE_TICKET_PRESENCE") {
    const data = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === data.ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].presence = data.presence;
    }
    return [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    return [...state];
  }
  
  if (action.type === "UPDATE_TICKET_TAGS") {
    const ticket = action.payload;
    const ticketIndex = state.findIndex(t => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].tags = ticket.tags;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const TicketsListCustom = (props) => {
  const {
    status,
    searchParam,
    tags,
    users,
    showAll,
    selectedQueueIds,
    updateCount,
    style,
    onButtonClick, 
    userViewTicketsWithoutDepartment,
  } = props;
  const classes = useStyles();
  
  const [pageNumber, setPageNumber] = useState(1);
  const [ticketsList, dispatch] = useReducer(reducer, []);

  const { user } = useContext(AuthContext);   
  const { profile, queues } = user;
  
  const socketManager = useContext(SocketContext);
 
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [status, searchParam, dispatch, showAll, tags, users, selectedQueueIds, userViewTicketsWithoutDepartment]);

  const { tickets, hasMore, loading } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    tags: JSON.stringify(tags),
    users: JSON.stringify(users),
    queueIds: JSON.stringify(selectedQueueIds),
  });

  useEffect(() => {
    const queueIds = queues.map((q) => q.id);
    let filteredTickets = tickets.filter((t) => !userViewTicketsWithoutDepartment ? queueIds.indexOf(t.queueId) > -1 : t.id);

// console.log('userViewTicketsWithoutDepartment: '+ userViewTicketsWithoutDepartment, 'profile: '+ profile +' status: '+ status);

    if (profile !== "admin") {
      if (!userViewTicketsWithoutDepartment) {
        if (['', 'closed'].includes(status))
          filteredTickets = tickets.filter((t) => (t.userId === user.id) || !['open', 'pending'].includes(t.status)); 
        else
          filteredTickets = tickets.filter((t) => (t.userId === user.id) && queueIds.indexOf(t.queueId) > -1); 
      } else {
        // if (['', 'closed'].includes(status))
        //   filteredTickets = tickets.filter((t) => (t.userId === user.id) || !['open', 'pending'].includes(t.status)); 
        // else
          // filteredTickets = tickets.filter((t) => (t.userId === user.id) && queueIds.indexOf(t.queueId) > -1);
          filteredTickets = tickets.filter((t) => 
            (t.userId === user.id && t.stats === 'open') || 
            (!t.userId && t.status === 'pending') ||
            (!t.userId || !t.queueId) || 
            (queueIds.indexOf(t.queueId) > -1)
          );
      }
    } 

    // dispatch({ type: "LOAD_TICKETS", payload: filteredTickets });
 
    if (profile === "admin") {      
      dispatch({ type: "LOAD_TICKETS", payload: tickets });
    } else {
      dispatch({ type: "LOAD_TICKETS", payload: filteredTickets });  
    }
  }, [tickets, status, queues, profile, user, userViewTicketsWithoutDepartment]);
 
//   useEffect(() => {
//     const companyId = localStorage.getItem("companyId");
//     const socket = socketManager.GetSocket(companyId);
 
//     const shouldUpdateTicket = (ticket) =>
//       (!ticket.userId || ticket.userId === user?.id || showAll) &&
//       (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

//     const notBelongsToUserQueues = (ticket) =>
//       ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

//     socket.on("connect", () => {
//       if (status) {
//         socket.emit("joinTickets", status);
//       } else {
//         socket.emit("joinNotification");
//       }
//     });
 
//     socket.on(`company-${companyId}-ticket`, (data) => {
//       console.log(`Chegou em: `+ data.action, `company-${companyId}-ticket`);

//       if (data.action === "updateUnread") {
//         dispatch({
//           type: "RESET_UNREAD",
//           payload: data.ticketId,
//         });
//       }
 
//       if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
//         dispatch({
//           type: "UPDATE_TICKET",
//           payload: data.ticket,
//         });
//       }

//       if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
//         dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
//       }

//       if (data.action === "delete") {
//         dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
//       }

//       if (data.action === "tagUpdate") {
//         dispatch({
//           type: "UPDATE_TICKET_TAGS",
//           payload: data.ticket,
//         });
//       }

//     });

//     socket.on(`company-${companyId}-appMessage`, (data) => {
//       console.log(`Chegou em: `+ data.action, `company-${companyId}-appMessage`);

//       const queueIds = queues.map((q) => q.id);
//       if (profile === "user" &&
//         (queueIds.indexOf(data.ticket.queue?.id) === -1 ||
//           data.ticket.queue === null)
//       ) {
//         return;
//       }

//       if (data.action === "create" && shouldUpdateTicket(data.ticket) && (String(status) !== '' || data.ticket.status !== '')) {
//         dispatch({
//           type: "UPDATE_TICKET_UNREAD_MESSAGES",
//           payload: data.ticket,
//         });
//       }
//     });

//     socket.on(`company-${companyId}-contact`, (data) => {
//       console.log(`Chegou em: `+ data.action, `company-${companyId}-contact`);

//       if (data.action === "update") {
//         dispatch({
//           type: "UPDATE_TICKET_CONTACT",
//           payload: data.contact,
//         });
//       }
//     });

//     socket.on(`company-${companyId}-presence`, (data) => {
//       console.log(`Chegou em: `, `company-${companyId}-presence`);

//       dispatch({
//         type: "UPDATE_TICKET_PRESENCE",
//         payload: data,
//       });
//     });

//     return () => {
//       if (status) {
//         socket.emit("leaveTickets", status);
//       } else {
//         socket.emit("leaveNotification");
//       }      
//       socket.disconnect();
//     };
//   }, [status, showAll, user, selectedQueueIds, tags, users, profile, queues, socketManager]);







// useEffect(() => {
//   const queueIds = queues.map((q) => q.id);
//   const filteredTickets = tickets.filter(
//     (t) => queueIds.indexOf(t.queueId) > -1
//   );

//   if (profile === "user") {
//     dispatch({ type: "LOAD_TICKETS", payload: filteredTickets });
//   } else {
//     dispatch({ type: "LOAD_TICKETS", payload: tickets });
//   }
// }, [tickets, queues, profile]);

useEffect(() => {
  const companyId = localStorage.getItem("companyId");
  const socket = socketManager.GetSocket(companyId);

  const shouldUpdateTicket = (ticket) =>
    (!ticket.userId || ticket.userId === user?.id || showAll) &&
    (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

  const notBelongsToUserQueues = (ticket) =>
    ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

  const onConnectTicketList = () => {
    if (status) {
      socket.emit("joinTickets", status);
    } else {
      socket.emit("joinNotification");
    }
  }
  
  const onCompanyTicket = (data) => {
    if (data.action === "updateUnread") {
      dispatch({ type: "RESET_UNREAD", payload: data.ticketId, });
    }

    if (data.action === "update" && data.ticket.status === status && shouldUpdateTicket(data.ticket)) {
      dispatch({ type: "UPDATE_TICKET", payload: data.ticket, });
    }
    
    if (//groups && 
      data.action === "update" && data.ticket.isGroup && shouldUpdateTicket(data.ticket)) {
      dispatch({ type: "UPDATE_TICKET", payload: data.ticket, });
    }

    if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
      dispatch({ type: "DELETE_TICKET", payload: data.ticket?.id });
    }

    if (data.action === "delete") {
      dispatch({ type: "DELETE_TICKET", payload: data?.ticketId });      
    }

    if (data.action === "removeFromList") {
      dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
    }

    if (data.action === "tagUpdate") {
      dispatch({ type: "UPDATE_TICKET_TAGS", payload: data.ticket, });
    }
  }
  
  const onCompanyAppMessage = (data) => {
  // console.debug("appMessage event received", data);
    // if (showTabGroups && !!data.ticket?.isGroup !== !!groups) {
    //   return;
    // }

    const queueIds = queues.map((q) => q.id);
    if (
      profile === "user" &&
      (queueIds.indexOf(data.ticket?.queue?.id) === -1 ||
        data.ticket.queue === null)
    ) {
      return;
    }

    if (
      data.action === "create" &&
      // (!showTabGroups || !!data.ticket?.isGroup === !!groups) &&
      shouldUpdateTicket(data.ticket) &&
      (status === undefined || data.ticket.status === status)
    ) {
      dispatch({ type: "UPDATE_TICKET_UNREAD_MESSAGES", payload: data.ticket, });
    }
  }

  const onCompanyContact = (data) => {
    if (data.action === "update") {
      dispatch({ type: "UPDATE_TICKET_CONTACT", payload: data.contact, });
    }
  }
  
  socketManager.onConnect(onConnectTicketList);

  socket.on(`company-${companyId}-ticket`, onCompanyTicket);
  socket.on(`company-${companyId}-appMessage`, onCompanyAppMessage);
  socket.on(`company-${companyId}-contact`, onCompanyContact );
  
  socket.on(`company-${companyId}-presence`, (data) => {
    dispatch({ type: "UPDATE_TICKET_PRESENCE", payload: data, });
  });

  return () => {
    if (status) {
      socket.emit("leaveTickets", status);
    } else {
      socket.emit("leaveNotification");
    }
    socket.disconnect();
  };
  
}, [status, showAll, user, selectedQueueIds, tags, users, profile, queues, socketManager]);








  useEffect(() => {
    if (typeof updateCount === "function") {
      updateCount(ticketsList.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsList]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <Paper className={classes.ticketsListWrapper} style={style}>
      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketLists}
        onScroll={handleScroll}
      >
        <List style={{ paddingTop: 0 }}>
          {ticketsList.length === 0 && !loading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          ) : (
            <>
              {ticketsList.map((ticket) => (
                <TicketListItemCustom 
                  ticket={ticket} 
                  key={ticket.id} 
                  onButtonClick={onButtonClick}
                  userViewTicketsWithoutDepartment={userViewTicketsWithoutDepartment}
                />
              ))}
            </>
          )}
          {loading && <TicketsListSkeleton />}
        </List>
      </Paper>
    </Paper>
  );
};

export default TicketsListCustom;
