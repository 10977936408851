import React, { useContext, useEffect, useRef, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModalCustom from "../TransferTicketModalCustom";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";

import ScheduleModal from "../ScheduleModal";
import { Fab, makeStyles } from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep"; 
import HistoryIcon from '@material-ui/icons/History';
import ShowTicketLogModal from "../ShowTicketLogModal"; 

const useStyles = makeStyles(theme => ({
  actionButtons: {
    marginRight: 6,
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  },
  invertedFabMenuBase: {
    borderRadius: 50, // Valor padrão, pode ser sobrescrito
    boxShadow: "none", // Remove a sombra
    width: theme.spacing(4), // Ajuste o tamanho de acordo com suas preferências
    height: theme.spacing(4),
    backgroundColor: "transparent",
    color: "grey",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.4)", // Animação de escala
      color: theme.mode === "light" ? theme.palette.primary.main : "#EEE"
    },
    "&:disabled": {
      backgroundColor: "transparent !important"
    }
  },
  icon: {
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      color: theme.mode === "light" ? theme.palette.primary.main : "#EEE"
    }
  },
  menuItem: {
    "&:hover $icon": {
      transform: "scale(1.4)",
	  color: theme.mode === "light" ? theme.palette.primary.main : "#EEE"
    }
  }
}));

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl }) => {
  const classes = useStyles();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);

  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [showTicketLogOpen, setShowTicketLogOpen] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDeleteTicket = async () => {
    try {
      await api.delete(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenConfirmationModal = e => {
    setConfirmationOpen(true);
    handleClose();
  };

  const handleShowLogTicket = () => { 
    setShowTicketLogOpen(true);
    handleClose();
  };

  const handleOpenTransferModal = e => {
    setTransferTicketModalOpen(true);
    handleClose();
  };

  const handleCloseTransferTicketModal = () => {
    if (isMounted.current) {
      setTransferTicketModalOpen(false);
    }
  };

  const handleOpenScheduleModal = () => {
    handleClose();
    setContactId(ticket.contact.id);
    setScheduleModalOpen(true);
  };

  const handleCloseScheduleModal = () => {
    setScheduleModalOpen(false);
    setContactId(null);
  };

  return (
    <>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={handleOpenScheduleModal}
        >
          <Fab
            aria-label="schedule-action"
            component="span"
            className={classes.invertedFabMenuBase} 
          >
            <ScheduleIcon className={classes.icon} />
          </Fab>
          {i18n.t("ticketOptionsMenu.schedule")}
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={handleOpenTransferModal}
        >
          <Fab
            aria-label="transfer-action"
            component="span"
            className={classes.invertedFabMenuBase} 
          >
            <CompareArrowsIcon className={classes.icon} />
          </Fab>
          {i18n.t("ticketOptionsMenu.transfer")}
        </MenuItem>
        <Can
          role={user.profile}
          perform="ticket-options:deleteTicket"
          yes={() => (
            <>
              <MenuItem
                className={classes.menuItem}
                onClick={handleOpenConfirmationModal}
              >
                <Fab
                  aria-label="delete-action"
                  component="span"
                  className={classes.invertedFabMenuBase} 
                >
                  <DeleteSweepIcon className={classes.icon} />
                </Fab>
                {i18n.t("ticketOptionsMenu.delete")}
              </MenuItem>
              
              <MenuItem 
                className={classes.menuItem}
                onClick={handleShowLogTicket}
              >
                <Fab
                  aria-label="log-action"
                  component="span"
                  className={classes.invertedFabMenuBase} 
                >
                  <HistoryIcon className={classes.icon} />
                </Fab>
                {i18n.t("ticketOptionsMenu.logTicket")}
              </MenuItem>
            </>
          )}
        />
      </Menu>
      {showTicketLogOpen && (
        <ShowTicketLogModal
            isOpen={showTicketLogOpen}
            handleClose={(e) => setShowTicketLogOpen(false)}
            ticketId={ticket.id}
        />
      )}
      <ConfirmationModal
        title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${
          ticket.id
        } ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${
          ticket.contact.name
        }?`}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteTicket}
      >
        {i18n.t("ticketOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <TransferTicketModalCustom
        modalOpen={transferTicketModalOpen}
        onClose={handleCloseTransferTicketModal}
        ticketid={ticket.id}
      />
      <ScheduleModal
        open={scheduleModalOpen}
        onClose={handleCloseScheduleModal}
        aria-labelledby="form-dialog-title"
        contactId={contactId}
      />
    </>
  );
};

export default TicketOptionsMenu;
