import React, { useState, useEffect, useContext, useRef } from "react";
// eslint-disable-next-line
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";
import { isNil } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import GestureIcon from "@material-ui/icons/Gesture";
import {
  Fab,  
  Menu,
  MenuItem,
  Tooltip,
  // eslint-disable-next-line
  useMediaQuery, InputAdornment,
  useTheme
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  CameraAlt,
  Description,
  Duo,
  PermMedia,
  Comment,
  Person
} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isString, isEmpty, isObject, has } from "lodash";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
// import { EditMessageContext } from "../../context/EditingMessage/EditingMessageContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";

import useQuickMessages from "../../hooks/useQuickMessages";
import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";
import useSettings from "../../hooks/useSettings";

// import MessageUploadMedias from "../MessageUploadMedias";
import ContactSendModal from "../ContactSendModal";
import CameraModal from "../CameraModal";
import axios from "axios";
import { blue, green, pink } from "@material-ui/core/colors";
import { isLinkOrEmail, isMobile } from "../../helpers/perso";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    backgroundColor: theme.mode === "dark" ? "" : "#eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  },

  newMessageBox: {
    backgroundColor: theme.mode === "dark" ? "" : "#eee",
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center"
  },

  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    backgroundColor: theme.mode === "dark" ? "#2a3942ff" : "#fff",
    border: theme.mode === "dark" ? "1px solid grey" : "none",
    borderRadius: 12,
    display: "flex",
    flex: 1
  },

  messageInput: {
    paddingLeft: 10,
    flex: 1
  },

  messageAlert: {
    width: "100%",
    display: "flex",
    padding: "10px",
    alignItems: "center",
    backgroundColor: "#F0E68C",
    color: "grey"
  },

  sendMessageIcons: {
    color: theme.mode === "dark" ? "" : "grey"
  },

  uploadInput: {
    display: "none"
  },

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.mode === "dark" ? "" : "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8"
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12
  },

  audioLoading: {
    color: green[500],
    opacity: "70%"
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle"
  },

  cancelAudioIcon: {
    color: "red"
  },

  sendAudioIcon: {
    color: "green"
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: theme.mode === "dark" ? "#2e2d2d" : "rgba(0, 0, 0, 0.05)",
    color: theme.mode === "dark" ? "grey" : "inherit",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative"
  },

  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden"
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96"
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef"
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500
  },
  messageQuickAnswersWrapper: {
    margin: 0,
    position: "absolute",
    bottom: "50px",
    background: theme.palette.background.default,
    padding: 0,
    border: "none",
    left: 0,
    width: "100%",
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxHeight: "30px",
        "&:hover": {
          background: theme.palette.background.paper,
          cursor: "pointer"
        }
      }
    }
  },

  flexContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  flexItem: {
    flex: 1
  },
  invertedFabMenuBase: {
    // border: "none",
    borderRadius: 50, // Valor padrão, pode ser sobrescrito
    boxShadow: "none", // Remove a sombra
    width: theme.spacing(4), // Ajuste o tamanho de acordo com suas preferências
    height: theme.spacing(4),
    backgroundColor: "transparent",
    color: "grey",
    // color: theme.mode === "dark" ? "#eee" : "grey", // "inherit", // A cor pode ser definida diretamente no componente
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.4)", // Animação de escala
      color: theme.mode === "light" ? theme.palette.primary.main : "#EEE"
    },
    "&:disabled": {
      backgroundColor: "transparent !important"
    }
  },
  icon: {
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      color: theme.mode === "light" ? theme.palette.primary.main : "#EEE"
    }
  },
  menuItem: {
    "&:hover $icon": {
      transform: "scale(1.4)"
    }
  }
}));

const EmojiOptions = ({
  disabled,
  showEmoji,
  setShowEmoji,
  handleAddEmoji
}) => {
  const classes = useStyles();

  return (
    <>
      <Tooltip title={i18n.t("messagesInput.emoji")} arrow>
        <IconButton
          aria-label="emojiPicker"
          component="span"
          disabled={disabled}
          onClick={() => setShowEmoji(prevState => !prevState)}
          className={classes.invertedFabMenuBase}
        >
          <MoodIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      {showEmoji ? (
        <div className={classes.emojiBox}>
          <Picker
            perLine={16}
            theme={"dark"}
            i18n={i18n}
            showPreview={true}
            showSkinTones={false}
            onSelect={handleAddEmoji}
          />
        </div>
      ) : null}
    </>
  );
};

const SignSwitch = ({
  width,
  setSignMessage,
  signMessage,
  userAlwaysSubmitSignature,
  profile,
  disableOption,
  setShowEmoji
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Tooltip title={i18n.t("messagesInput.signMessage")} arrow>
      <IconButton
        name="showAllTickets"
        aria-label="show-all-tickets"
        component="span"
        className={classes.invertedFabMenuBase}
        onClick={() => {
          setShowEmoji(false);
          if (!userAlwaysSubmitSignature || profile.toUpperCase() === "ADMIN") {
            setSignMessage(!signMessage);
          }
        }}
        disabled={
          (userAlwaysSubmitSignature && profile.toUpperCase() !== "ADMIN") ||
          disableOption
        }
      >
        {signMessage ? (
          <GestureIcon
            className={classes.icon}
            style={{
              color:
                theme.mode === "light" ? theme.palette.primary.main : "#EEE"
            }}
          />
        ) : (
          <GestureIcon className={classes.icon} style={{ color: "grey" }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

// eslint-disable-next-line
const FileInput = props => {
  const { handleChangeMedias, disableOption } = props;
  const classes = useStyles();
  return (
    <>
      <input
        multiple
        type="file"
        id="upload-button"
        disabled={disableOption}
        className={classes.uploadInput}
        onChange={handleChangeMedias}
        style={{ marginRight: 0 }}
      />
      <label htmlFor="upload-button">
        <IconButton
          aria-label="upload"
          component="span"
          disabled={disableOption}
          className={classes.invertedFabMenuBase}
        >
          <AttachFileIcon className={classes.icon} />
        </IconButton>
      </label>
    </>
  );
};

const ActionButtons = props => {
  const {
    inputMessage,
    loading,
    recording,
    ticketStatus,
    handleSendMessage,
    handleCancelAudio,
    handleUploadAudio,
    handleStartRecording
  } = props;
  const classes = useStyles();

  if (inputMessage) {
    return (
      <Tooltip title={"Enviar mensagem"} arrow>
        <IconButton
          aria-label="sendMessage"
          component="span"
          onClick={handleSendMessage}
          disabled={loading}
          className={classes.invertedFabMenuBase}
        >
          <SendIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    );
  } else if (recording) {
    return (
      <div className={classes.recorderWrapper}>
        <Tooltip title={"Cancelar áudio"} arrow>
          <IconButton
            aria-label="cancelRecording"
            component="span"
            fontSize="large"
            disabled={loading}
            onClick={handleCancelAudio}
            className={classes.invertedFabMenuBase}
          >
            <HighlightOffIcon
              className={`${classes.cancelAudioIcon} ${classes.icon}`}
            />
          </IconButton>
        </Tooltip>
        {loading ? (
          <div>
            <CircularProgress
              className={`${classes.audioLoading} ${classes.icon}`}
            />
          </div>
        ) : (
          <RecordingTimer className={classes.icon} />
        )}

        <Tooltip title={"Enviar áudio"} arrow>
          <IconButton
            aria-label="sendRecordedAudio"
            component="span"
            onClick={handleUploadAudio}
            disabled={loading}
            className={classes.invertedFabMenuBase}
          >
            <CheckCircleOutlineIcon
              className={`${classes.sendAudioIcon} ${classes.icon}`}
            />
          </IconButton>
        </Tooltip>
      </div>
    );
  } else {
    return (
      <Tooltip title={"Gravar áudio"} arrow>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading || ticketStatus !== "open"}
          onClick={handleStartRecording}
          className={classes.invertedFabMenuBase}
        >
          <MicIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    );
  }
};

const CustomInput = props => {
  const {
    loading,
    inputRef,
    ticketStatus,
    inputMessage,
    setInputMessage,
    handleSendMessage,
    handleInputPaste,    
    // handleChangeMedias,
    disableOption,
    setSelectedOptionId,
    selectedOptionId,
    isPrivate
  } = props;
  const classes = useStyles();

  const [quickMessages, setQuickMessages] = useState([]);
  const [options, setOptions] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);

  const { user } = useContext(AuthContext);

  const { list: listQuickMessages } = useQuickMessages();

  useEffect(() => {
    async function fetchData() {
      const companyId = localStorage.getItem("companyId");
      const messages = await listQuickMessages({ companyId, userId: user.id });
      const options = messages.map(m => {
        let truncatedMessage = m.message;
        if (isString(truncatedMessage) && truncatedMessage.length > 35) {
          truncatedMessage = m.message.substring(0, 35) + "...";
        }
        return {
          id: m.id,
          value: m.message,
          label: `/${m.shortcode} - ${truncatedMessage}`
        };
      });
      setQuickMessages(options);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isString(inputMessage) &&
      !isEmpty(inputMessage) &&
      inputMessage.length > 0
    ) {
      const firstWord = inputMessage.charAt(0);
      setPopupOpen(firstWord.indexOf("/") > -1);

      const filteredOptions = quickMessages.filter(
        m => m.label.indexOf(inputMessage) > -1
      );
      setOptions(filteredOptions);
    } else {
      setPopupOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputMessage]);

  const onKeyPress = e => {
    if (loading || e.shiftKey) return;
    else if (e.key === "Enter" && !isMobile()) {
      handleSendMessage();
    }
  };

  const onPaste = e => {
    if (ticketStatus === "open" || ticketStatus === "group") {
      handleInputPaste(e);
    }
  };

  const renderPlaceholder = () => {
    if (ticketStatus === "open" || ticketStatus === "group") {
      return i18n.t("messagesInput.placeholderOpen");
    }
    return i18n.t("messagesInput.placeholderClosed");
  };

  const setInputRef = input => {
    if (input) {
      input.focus();
      inputRef.current = input;
    }
  };

  const handleKeyDown = event => {
    if (event.key === "Escape" && popupOpen) {
      setPopupOpen(false);
    }
    if (event.key === "Escape" && selectedOptionId) {
      setSelectedOptionId(null);
    }
    if (popupOpen) {
      return;
    }
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.stopPropagation();
    }
  };

  return (
    <div
      className={classes.messageInputWrapper}
      style={{
        backgroundColor: isPrivate ? "#F0E68C" : ""
      }}
    >
      <Autocomplete
        freeSolo
        open={popupOpen}
        id="grouped-demo"
        value={inputMessage}
        options={options}
        closeIcon={null}
        disabled={disableOption}
        getOptionLabel={option => {
          if (isObject(option)) {
            return option.label;
          } else {
            return option;
          }
        }}
        onChange={(event, opt) => {
          if (isObject(opt) && has(opt, "value")) {
            setInputMessage(opt.value);
            setSelectedOptionId(opt.id);

            setTimeout(() => {
              inputRef.current.scrollTop = inputRef.current.scrollHeight;
            }, 200);
          }
        }}
        onInputChange={(event, opt, reason) => {
          if (reason === "input") {
            setInputMessage(event.target.value);
          }
        }}
        onKeyDown={handleKeyDown}
        onPaste={onPaste}
        onKeyPress={onKeyPress}
        style={{ width: "100%" }}
        renderInput={params => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              {...params.InputProps}
              {...rest}
              className={classes.messageInput}
              style={{
                color: isPrivate ? "gray" : ""
              }}
              disabled={disableOption}
              inputRef={setInputRef}
              placeholder={renderPlaceholder()}
              multiline
              maxRows={7}
              onKeyDown={handleKeyDown}
              inputProps={{
                ...params.inputProps,
                spellCheck: true,   
              }}
              // endAdornment={
              //   isMobile() &&
              //   <InputAdornment position="end">
              //     <input
              //       type="file"
              //       id="camera-button"
              //       accept="image/*"
              //       capture="camera"
              //       className={classes.uploadInput}
              //       onChange={handleChangeMedias}
              //     />
              //     <label htmlFor="camera-button">
              //       <IconButton
              //         aria-label="camera-upload"
              //         component="span"
              //         disabled={disableOption}
              //       >
              //         <CameraAlt className={classes.cameraIcon} />
              //       </IconButton>
              //     </label>
              //   </InputAdornment>
              // } 
            />
          );
        }}
      />
    </div>
  );
};

const MessageInputCustom = props => {
  const { ticketStatus, ticketId, droppedFiles } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const inputRef = useRef();
  const { setReplyingMessage, replyingMessage } = useContext(ReplyMessageContext);
  // const { setEditingMessage, editingMessage } = useContext(EditMessageContext);
  const { user } = useContext(AuthContext);
  const { findValuesOf } = useSettings();

  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);
  const [userAlwaysSubmitSignature, setUserAlwaysSubmitSignature] =
    useState(true);

  const [modalCameraOpen, setModalCameraOpen] = useState(false);
  const [privateMessage, setPrivateMessage] = useState(false);
  const [privateMessageInputVisible, setPrivateMessageInputVisible] =
    useState(false);
  const [senVcardModalOpen, setSenVcardModalOpen] = useState(false);
  // eslint-disable-next-line
  const [showModalMedias, setShowModalMedias] = useState(false);
  const [onDragEnter, setOnDragEnter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placeholderText, setPlaceHolderText] = useState("");
 
  const isMounted = useRef(true);

  useEffect(() => {
    const fetchSession = async () => {
      if (user.profile.toUpperCase() !== "ADMIN") return;

      const values = await findValuesOf(["userAlwaysSubmitSignature"]);
      setUserAlwaysSubmitSignature(
        values.userAlwaysSubmitSignature === "enabled"
      );
      if (values.userAlwaysSubmitSignature === "enabled")
        setSignMessage(values.userAlwaysSubmitSignature === "enabled");
    };
    fetchSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (droppedFiles && droppedFiles.length > 0) {
      const selectedMedias = Array.from(droppedFiles);
      setMedias(selectedMedias);
      setShowModalMedias(true);
    }
  }, [droppedFiles]);

  useEffect(() => {
    inputRef.current.focus();
  }, [replyingMessage]);

  useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) setOnDragEnter(false);
    }, 1000);
    // eslint-disable-next-line
  }, [onDragEnter === true]);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setInputMessage("");
      setSelectedOptionId(null);
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
      // setEditingMessage(null);
      setPrivateMessage(false);
      setPrivateMessageInputVisible(false);
    };
  }, [
    ticketId,
    setReplyingMessage //, setEditingMessage
  ]);

  // const handleChangeInput = e => {
  // 	if (isObject(e) && has(e, 'value')) {
  // 		setInputMessage(e.value);
  // 	} else {
  // 		setInputMessage(e.target.value)
  // 	}
  // };

  // Determine o texto do placeholder com base no ticketStatus
  useEffect(() => {
    if (ticketStatus === "open" || ticketStatus === "group") {
      setPlaceHolderText(i18n.t("messagesInput.placeholderOpen"));
    } else {
      setPlaceHolderText(i18n.t("messagesInput.placeholderClosed"));
    }

    // Limitar o comprimento do texto do placeholder apenas em ambientes mobile
    const maxLength = isMobile() ? 20 : Infinity; // Define o limite apenas em mobile

    if (isMobile() && placeholderText.length > maxLength) {
      setPlaceHolderText(placeholderText.substring(0, maxLength) + "...");
    }
  }, [ticketStatus, placeholderText]);

  const handleAddEmoji = e => {
    let emoji = e.native;
    setInputMessage(prevState => prevState + emoji);
  };

  const handleChangeMedias = e => {
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  const handleInputPaste = e => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  };

  // eslint-disable-next-line
  const handleCloseModalMedias = () => {
    setShowModalMedias(false);
  };

  // eslint-disable-next-line
  const handleUploadMedia = async e => {
    setLoading(true);
    e.preventDefault();

    if (!medias.length) {
      console.log("Nenhuma mídia selecionada.");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("fromMe", true);
    formData.append("isPrivate", privateMessage ? "true" : "false");
    medias.forEach(media => {
      formData.append("medias", media);
      formData.append("body", media.name);
    });

    try {
      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setLoading(false);
    setMedias([]);
    setShowModalMedias(false);
    setPrivateMessage(false);
    setPrivateMessageInputVisible(false);
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
    setLoading(true);

    const userName = privateMessage
      ? `${user?.name} - Mensagem privada`
      : user?.name;

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body:
        (signMessage || privateMessage) && !isLinkOrEmail(inputMessage.trim()) //&& !editingMessage
          ? `*${userName}:*\n${inputMessage.trim()}`
          : inputMessage.trim(),
      quotedMsg: replyingMessage,
      selectedOptionId: selectedOptionId,
      isPrivate: privateMessage
    };
    try {
      // if (editingMessage !== null) {
      //   await api.post(`/messages/edit/${editingMessage.id}`, message);
      // } else
      //{
      await api.post(`/messages/${ticketId}`, message);
      //}
    } catch (err) {
      toastError(err);
    }

    setInputMessage("");
    setSelectedOptionId(null);
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
    setPrivateMessage(false);
    // setEditingMessage(null);
    setPrivateMessageInputVisible(false);
    handleMenuItemClick();
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `audio-record-site-${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  // const disableOption = () => {
  //   return loading || recording || ticketStatus !== "open";
  // };

  const renderVCard = (messageBody, isQuotedMsgRender) => {
    let array = messageBody.split("\n");
    let obj = [];
    let contact = "";
    for (let index = 0; index < array.length; index++) {
      const v = array[index];
      let values = v.split(":");
      for (let ind = 0; ind < values.length; ind++) {
        if (values[ind].indexOf("+") !== -1) {
          obj.push({ number: values[ind] });
        }
        if (values[ind].indexOf("FN") !== -1) {
          contact = values[ind + 1];
        }
      }
    }
    return (
      <VcardPreview
        contact={contact}
        numbers={obj[0].number}
        // ticket={ticket}
        isQuotedMsgRender={isQuotedMsgRender}
      />
    );
  };

  const renderLocation = (messageBody, isQuotedMsgRender) => {
    if (messageBody.split("|").length >= 2) {
      try {
        let locationParts = messageBody.split("|");
        let imageLocation = locationParts[0];
        let linkLocation = locationParts[1];

        let descriptionLocation = null;

        if (locationParts.length > 2) {
          descriptionLocation = messageBody?.split("|")[2];
        }
        return (
          <LocationPreview
            image={imageLocation}
            link={linkLocation}
            description={descriptionLocation}
            isQuotedMsgRender={isQuotedMsgRender}
          />
        );
      } catch (err) {
        //console.log("checkMessageMedia ~ err", err)
      }
    }
  };

  const renderReplyingMessage = message => {
    return (
      <div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe
            })}
          ></span>
          <div className={classes.replyginMsgBody}>
            {!message.fromMe && (
              <span className={classes.messageContactName}>
                <MarkdownWrapper>{message.contact?.name}</MarkdownWrapper>
              </span>
            )}
            {message.mediaType === "contactMessage" ? (
              renderVCard(message.body, true)
            ) : message.mediaType === "locationMessage" ? (
              renderLocation(message.body, true)
            ) : (
              <MarkdownWrapper>{message.body}</MarkdownWrapper>
            )}
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading || ticketStatus !== "open"}
          onClick={() => setReplyingMessage(null)}
          className={classes.invertedFabMenuBase}
        >
          <ClearIcon className={classes.icon} />
        </IconButton>
      </div>
    );
  };

  const handleSendContatcMessage = async vcard => {
    setSenVcardModalOpen(false);
    setLoading(true);

    if (isNil(vcard)) {
      setLoading(false);
      return;
    }

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: null,
      quotedMsg: replyingMessage,
      isPrivate: privateMessage,
      vCard: vcard
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      toastError(err);
    }

    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
    // setEditingMessage(null);
    setPrivateMessage(false);
    setPrivateMessageInputVisible(false);
  };

  const handlePrivateMessage = () => {
    setShowEmoji(false);
    setPrivateMessage(!privateMessage);
    setPrivateMessageInputVisible(!privateMessageInputVisible);
    inputRef.current.focus();
  };

  const handleUploadCamera = async blob => {
    setLoading(true);
    try {
      const formData = new FormData();
      const filename = `${new Date().getTime()}.png`;
      formData.append("medias", blob, filename);
      formData.append("body", privateMessage ? `\u200d` : "");
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleCapture = imageData => {
    if (imageData) {
      handleUploadCamera(imageData);
    }
  };

  // eslint-disable-next-line
  const handleChangeInput = e => {
    setInputMessage(e.target.value);
  };

  // eslint-disable-next-line
  const handleQuickAnswersClick = async value => {
    if (value.mediaPath) {
      try {
        const { data } = await axios.get(value.mediaPath, {
          responseType: "blob"
        });

        handleUploadQuickMessageMedia(data, value.value);
        setInputMessage("");
        return;
        //  handleChangeMedias(response)
      } catch (err) {
        toastError(err);
      }
    }

    setInputMessage("");
    setInputMessage(value.value);
  };

  const handleUploadQuickMessageMedia = async (blob, message) => {
    setLoading(true);
    try {
      const extension = blob.type.split("/")[1];

      const formData = new FormData();
      const filename = `${new Date().getTime()}.${extension}`;
      formData.append("medias", blob, filename);
      formData.append("body", privateMessage ? `\u200d${message}` : message);
      formData.append("fromMe", true);

      if (isMounted.current) {
        await api.post(`/messages/${ticketId}`, formData);
      }
    } catch (err) {
      toastError(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const handleInputDrop = e => {
    e.preventDefault();
    if (e.dataTransfer.files[0]) {
      const selectedMedias = Array.from(e.dataTransfer.files);
      setMedias(selectedMedias);
      setShowModalMedias(true);
    }
  };

  const handleOpenMenuClick = event => {
    setShowEmoji(false);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = event => {
    setAnchorEl(null);
  };

  const handleSendContactModalOpen = async () => {
    handleMenuItemClick();
    setSenVcardModalOpen(true);
  };

  const handleSendLinkVideo = async () => {
    const newCode = new Date().getTime().toString(); 
    const link = `https://meet.jit.si/${ticketId}${newCode}`;
    setInputMessage(link);
  };

  const handleCameraModalOpen = async () => {
    handleMenuItemClick();
    setModalCameraOpen(true);
  };

  // eslint-disable-next-line
  const handleCancelSelection = () => {
    setMedias([]);
    setShowModalMedias(false);
  };
 
  if (medias.length > 0)
    return (
      <Paper
        elevation={0}
        square
        className={classes.viewMediaInputWrapper}
        onDragEnter={() => setOnDragEnter(true)}
        onDrop={e => handleInputDrop(e)}        
      >
        {/* {showModalMedias && (
          <MessageUploadMedias
            isOpen={showModalMedias}
            files={medias}
            onClose={handleCloseModalMedias}
            onSend={handleUploadMedia}
            onCancelSelection={handleCancelSelection}
          />
        )} */}

        <Tooltip title="Cancelar envio de anexo(s)" arrow>
          <IconButton
            aria-label="cancel-upload"
            component="span"
            onClick={e => setMedias([])}
            className={classes.invertedFabMenuBase}
          >
            <CancelIcon className={classes.icon} />
          </IconButton>
        </Tooltip>

        {loading ? (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        ) : (
          <span>
            {medias.map((media, index) => (
              <React.Fragment key={index}>
                {media.name}
                {index < medias.length - 1 && ", "}
              </React.Fragment>
            ))}
          </span>
        )}

        <Tooltip title="Enviar anexo" arrow>
          <IconButton
            aria-label="send-upload"
            component="span"
            onClick={handleUploadMedia}
            disabled={loading}
            className={classes.invertedFabMenuBase}
          >
            <SendIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Paper>
    );
  else {
    return (
      <>
        {modalCameraOpen && (
          <CameraModal
            isOpen={modalCameraOpen}
            onRequestClose={() => setModalCameraOpen(false)}
            onCapture={handleCapture}
          />
        )}
        {senVcardModalOpen && (
          <ContactSendModal
            modalOpen={senVcardModalOpen}
            onClose={c => {
              handleSendContatcMessage(c);
            }}
          />
        )}
        {selectedOptionId && (
          <div className={classes.messageAlert}>
            <AttachFileIcon className={classes.icon} />
            <span>Ao enviar esta mensagem, poderá ser enviado anexo(s).</span>
            <div style={{ marginLeft: "auto" }}>
              <Tooltip title="Cancelar envio de anexo(s)" arrow>
                <IconButton
                  aria-label="cancel-upload"
                  component="span"
                  onClick={e => setSelectedOptionId(null)}
                  className={classes.invertedFabMenuBase}
                >
                  <CancelIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
        <Paper
          square
          elevation={0}
          className={classes.mainWrapper}
          onDragEnter={() => setOnDragEnter(true)}
          onDrop={e => handleInputDrop(e)}
        >
          {
            replyingMessage && renderReplyingMessage(replyingMessage)
            // || (editingMessage && renderReplyingMessage(editingMessage))
          }
          <div className={classes.newMessageBox}>
            {
              //!isMobile() && 
              (
              <EmojiOptions
                disabled={loading || recording || ticketStatus !== "open"} //{disableOption}
                handleAddEmoji={handleAddEmoji}
                showEmoji={showEmoji}
                setShowEmoji={setShowEmoji}
              />)
            }

            <Tooltip title={i18n.t("messagesInput.attach")} arrow>
              <Fab
                disabled={loading || recording || ticketStatus !== "open"}
                aria-label="uploadMedias"
                component="span"
                className={classes.invertedFabMenuBase}
                onClick={handleOpenMenuClick}
              >
                <AddIcon className={classes.icon} />
              </Fab>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuItemClick}
              id="simple-menu"
            >
              <MenuItem
                onClick={handleMenuItemClick}
                className={classes.menuItem}
              >
                <input
                  multiple
                  type="file"
                  id="upload-img-button"
                  accept="image/*, video/*, audio/* "
                  className={classes.uploadInput}
                  onChange={handleChangeMedias}
                />
                <label htmlFor="upload-img-button">
                  <Fab
                    aria-label="upload-img"
                    component="span"
                    className={classes.invertedFabMenuBase}
                    style={{ color: blue[800] }}
                  >
                    <PermMedia className={classes.icon} />
                  </Fab>
                  {i18n.t("messagesInput.type.imageVideo")}
                </label>
              </MenuItem>
              { //!isMobile() && 
              (
                <MenuItem
                  onClick={handleCameraModalOpen}
                  className={classes.menuItem}
                >
                  <Fab
                    className={classes.invertedFabMenuBase}
                    style={{ color: pink[500] }}
                  >
                    <CameraAlt className={classes.icon} />
                  </Fab>
                  {i18n.t("messagesInput.type.cam")}
                </MenuItem>
              )}
              <MenuItem
                onClick={handleMenuItemClick}
                className={classes.menuItem}
              >
                <input
                  multiple
                  type="file"
                  id="upload-doc-button"
                  accept="application/*, text/*"
                  className={classes.uploadInput}
                  onChange={handleChangeMedias}
                />
                <label htmlFor="upload-doc-button">
                  <Fab
                    aria-label="upload-img"
                    component="span"
                    className={classes.invertedFabMenuBase}
                    style={{ color: "#7f66ff" }}
                  >
                    <Description className={classes.icon} />
                  </Fab>
                  {i18n.t("messagesInput.type.document")}
                </label>
              </MenuItem>
              <MenuItem
                onClick={handleSendContactModalOpen}
                className={classes.menuItem}
              >
                <Fab
                  className={classes.invertedFabMenuBase}
                  style={{ color: blue[500] }}
                >
                  <Person className={classes.icon} />
                </Fab>
                {i18n.t("messagesInput.type.contact")}
              </MenuItem>
              <MenuItem
                onClick={handleSendLinkVideo}
                className={classes.menuItem}
              >
                <Fab
                  className={classes.invertedFabMenuBase}
                  style={{ color: green[500] }}
                >
                  <Duo className={classes.icon} />
                </Fab>
                {i18n.t("messagesInput.type.meet")}
              </MenuItem>
            </Menu>

            <SignSwitch
              width={props.width}
              setSignMessage={setSignMessage}
              signMessage={signMessage}
              userAlwaysSubmitSignature={userAlwaysSubmitSignature}
              profile={user.profile}
              disableOption={loading || recording || ticketStatus !== "open"} //{disableOption}
              setShowEmoji={setShowEmoji}
            />

            <Tooltip
              title={i18n.t("messagesInput.tooltip.privateMessage")}
              arrow
            >
              <IconButton
                aria-label="send-upload"
                component="span"
                className={classes.invertedFabMenuBase}
                onClick={handlePrivateMessage}
              >
                {privateMessage ? (
                  <Comment
                    className={classes.icon}
                    style={{
                      color:
                        theme.mode === "light"
                          ? theme.palette.primary.main
                          : "#EEE"
                    }}
                  />
                ) : (
                  <Comment className={classes.icon} />
                )}
              </IconButton>
            </Tooltip>

            {/* <FileInput
              disableOption={loading || recording || ticketStatus !== "open"} //{disableOption}
              handleChangeMedias={handleChangeMedias}
            /> */}

            {/* <SignSwitch
              width={props.width}
              setSignMessage={setSignMessage}
              signMessage={signMessage}
              userAlwaysSubmitSignature={userAlwaysSubmitSignature}
              profile={user.profile}
              disableOption={loading || recording || ticketStatus !== "open"} //{disableOption}
            /> */}

            <CustomInput
              loading={loading}
              inputRef={inputRef}
              ticketStatus={ticketStatus}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              // handleChangeInput={handleChangeInput}
              handleSendMessage={handleSendMessage}
              handleInputPaste={handleInputPaste}
              handleChangeMedias={handleChangeMedias}
              disableOption={loading || recording || ticketStatus !== "open"}
              setSelectedOptionId={setSelectedOptionId}
              selectedOptionId={selectedOptionId}
              isPrivate={privateMessageInputVisible}
            />

            <ActionButtons
              inputMessage={inputMessage}
              loading={loading}
              recording={recording}
              ticketStatus={ticketStatus}
              handleSendMessage={handleSendMessage}
              handleCancelAudio={handleCancelAudio}
              handleUploadAudio={handleUploadAudio}
              handleStartRecording={handleStartRecording}
            />
          </div>
        </Paper>
      </>
    );
  }
};

export default withWidth()(MessageInputCustom);
