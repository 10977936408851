import React, { useState, useEffect } from "react";
import { i18n } from "../../translate/i18n";
import {
  Stepper,
  Step,
  StepLabel,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import { format, parseISO } from "date-fns";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const typeDescriptions = {
  create: i18n.t("showTicketLogModal.options.create"),
  accepted: i18n.t("showTicketLogModal.options.accepted"),
  chatBot: i18n.t("showTicketLogModal.options.chatBot"),
  queue: i18n.t("showTicketLogModal.options.queue"),
  open: i18n.t("showTicketLogModal.options.open"),
  access: i18n.t("showTicketLogModal.options.access"),
  transfered: i18n.t("showTicketLogModal.options.transfered"),
  receivedTransfer: i18n.t("showTicketLogModal.options.receivedTransfer"),
  pending: i18n.t("showTicketLogModal.options.pending"),
  closed: i18n.t("showTicketLogModal.options.closed"),
  reopen: i18n.t("showTicketLogModal.options.reopen"),
  redirect: i18n.t("showTicketLogModal.options.redirect"),
  autoClose: i18n.t("showTicketLogModal.options.autoClose"),
  returnQueue: i18n.t("showTicketLogModal.options.returnQueue"),
};

const getLogLabel = (log) => {
  const userOrQueue =
    log.type === "queue" || log.type === "redirect"
      ? log?.queue?.name
      : log.type === "receivedTransfer"
      ? `${log?.queue?.name} - ${log?.user?.name}`
      : log.type === "autoClose"
      ? "Plataforma"
      : log?.user?.name;

  return `${userOrQueue} ${typeDescriptions[log.type]} - ${format(parseISO(log?.createdAt), "dd/MM/yyyy HH:mm")}`;
};

const ShowTicketLogModal = ({ isOpen, handleClose, ticketId }) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    if (!ticketId) return;

    const fetchLogs = async () => {
      try {
        const { data } = await api.get(`/tickets-log/${ticketId}`);
        if (!data.length) {
          toast.warn("Nenhum log foi encontrado para este ticket!");
          handleClose();
          return;
        }
        setLogs(data);
      } catch (err) {
        toastError(err);
      }
    };

    const delayDebounceFn = setTimeout(fetchLogs, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, handleClose]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{i18n.t("showTicketLogModal.title.header")}</DialogTitle>
      <DialogContent>
        <Paper>
          <Stepper activeStep={0} orientation="vertical">
            {logs.map((log, index) => (
              <Step key={index}>
                <StepLabel>{getLogLabel(log)}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowTicketLogModal;
