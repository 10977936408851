import React, { useContext, useEffect, useState } from "react";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import TicketsCustom from "../TicketsCustom"
import TicketAdvanced from "../TicketsAdvanced";
import useAuth from "../../hooks/useAuth.js";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext.js";
import useSettings from "../../hooks/useSettings/index.js";

function TicketResponsiveContainer (props) {
    const history = useHistory();

    const { user } = useContext(AuthContext); 
    const { findValuesOf } = useSettings();

	const { getStatusAndDueDateAssignature: getStatusCompany } = useAuth();

    const [ userViewTicketsWithoutDepartment, setUserViewTicketsWithoutDepartment ] = useState(false);

    useEffect(() => {
        const fetchSession = async () => {
        if (user.profile.toUpperCase() !== "ADMIN") { 
            const values = await findValuesOf(['userViewTicketsWithoutDepartment']);
            setUserViewTicketsWithoutDepartment(values.userViewTicketsWithoutDepartment === 'enabled');
        } 
        };
        fetchSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

	useEffect(() => {
	  async function findData() {
		try {
		  const status = await getStatusCompany();
		  if (!status) {
			toast.warn("A plataforma requer atenção do administrador!");
			setTimeout(() => {
			  if (user.profile === "admin")
				history.push("/invoices");
			  else
				history.push("/helps");
			}, 1000);
		  }
		} catch (e) {
		  toast.error(e);
		}    
	  }
	  findData();
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);        
      
    if (isWidthUp('md', props.width)) {
        return <TicketsCustom userViewTicketsWithoutDepartment={userViewTicketsWithoutDepartment}/>;    
    }
    return <TicketAdvanced userViewTicketsWithoutDepartment={userViewTicketsWithoutDepartment}/>
}

export default withWidth()(TicketResponsiveContainer);