import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  Grid,
  Paper,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";  
import ItemSelect from "../ItemSelect";
import IOSSwitch from "../IOSSwitch";
import useQueues from "../../hooks/useQueues";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    // gap: 4
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles
  },  
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  btnLeft: {
    display: "flex",
    marginRight: "auto",
    marginLeft: 12,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const DialogflowSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Obrigatório"),
  // projectName: Yup.string()
  //   .min(3, "Muito curto!")
  //   .max(100, "Muito longo!")
  //   .required(),
  // jsonContent: Yup.string().min(3, "Muito curto!").required(),
  // language: Yup.string().min(2, "Muito curto!").max(50, "Muito longo!").required(),
});



const QueueIntegration = ({ open, onClose, currentId, companyId }) => {
  const classes = useStyles();

  let initialState = {
    type: "nenhum",
    name: "",
    projectName: "",
    jsonContent: "",
    language: "",
    urlApi: "",
    typebotDelayMessage: 1000,
    typebotExpires: 1,
    typebotKeywordFinish: "",
    typebotKeywordRestart: "",
    typebotRestartMessage: "",
    typebotSlug: "",
    typebotUnknownMessage: "",
    tokenApiKey: "",
    clientId: "",
    clientSecret: "",
    apiVersion: "",
    outerInfo: "",   
  };
 
  const [integration, setIntegration] = useState(initialState); 
  const [temperature, setTemperature] = useState('0.7'); 
  const [maxTokens, setMaxTokens] = useState('100'); 
  const [maxMessages, setMaxMessages] = useState('10');  
  const [queueId, setQueueId] = useState('0'); 
  const [itemsQueues, setItemsQueues] = useState([]);
  const [subtype, setSubtype] = useState('texto');  
  const [sandbox, setSandbox] = useState(true); 

  const { findAll: findAllQueues } = useQueues();
    
  const subtypes = [
    {value: 'texto', name: "Texto"},
    {value: 'alloy', name: "Voz Alloy"},
    {value: 'echo', name: "Voz Echo"},
    {value: 'fable', name: "Voz Fable"},
    {value: 'onyx', name: "Voz Onyx"},
    {value: 'nova', name: "Voz Nova"},
    {value: 'shimmer', name: "Voz Shimmer"},
  ];  

  useEffect(() => {
    (async () => {
      if (!currentId) return;
      try { 
        const { data } = await api.get(`/queueIntegration/${currentId}`);           
        if (data) { 
          setTemperature(data.temperature);
          setMaxTokens(data.maxTokens);
          setMaxMessages(data.maxMessages);
          setQueueId(data.queueId); 
          setSandbox(data.sandbox);
          setSubtype(data.subtype);

          setIntegration((prevState) => {
            return { ...prevState, ...data };
          });
        }
      } catch (err) {
        toastError(err);
      } 
    })();

    return () => {
      setIntegration(initialState);
    };
  // eslint-disable-next-line
  }, [currentId, open]);
 
  useEffect(() => {
    (async () => {
      try {
          const data = await findAllQueues(); 
          const itemsQueuesData = [
            { value: 0, name: "Nenhuma" },
            ...data?.map((queue) => ({ value: queue.id, name: queue.name })),
          ];   
          setItemsQueues(itemsQueuesData);  
      } catch (err) {
        toastError(err);
      }
    })();
  // eslint-disable-next-line
  }, [currentId, open]); 

  const handleClose = () => {
    onClose(); 
    setTemperature('0.7');
    setMaxTokens('100');
    setMaxMessages('10');
    setQueueId('0');    
    setSandbox(true);
    setSubtype('texto');
    setIntegration(initialState);
  };
 
  const handleSaveDialogflow = async (values) => {
    try {
      values.projectName = values.name;
      values.maxTokens = maxTokens;
      values.maxMessages = maxMessages;
      values.queueId = queueId; 
      values.sandbox = sandbox;
      values.subtype = subtype;
      
      if (values.integrationId === '0' || values.integrationId === 0)
        values.integrationId = null; 
      if (values.queueId === '0' || values.queueId === 0)
        values.queueId = null;

      if (values.type === 'openai') {
        values.temperature = parseFloat(temperature.replace(",", "."));
        if (values.temperature < 0.1 || values.temperature > 2) { 
          toastError('A temperatura deve estar entre 0.1 e 2');
          return;
        }
      } else {
        values.temperature = null;
        values.maxTokens = null;
        values.maxMessages = null;
      }
      
      if (currentId) {
        await api.put(`/queueIntegration/${currentId}`, values);
        toast.success(i18n.t("queueIntegrationModal.messages.editSuccess"));
      } else {
        await api.post("/queueIntegration", values);
        toast.success(i18n.t("queueIntegrationModal.messages.addSuccess"));
      }
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };
  
  const handleSubtypeChange = (selectedId) => {
    setSubtype(selectedId);
  };
   
  const handleQueueIdChange = (selectedId) => {
    setQueueId(selectedId);
  };
 
  const handleSandboxChange = async e => {
    const { checked } = e.target;
    setSandbox(checked);    
  };

  return (
    <div className={classes.root}>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        fullWidth 
        maxWidth="md" 
        scroll="paper"
      >
        <DialogTitle>
          {currentId
            ? `${i18n.t("queueIntegrationModal.title.edit")}`
            : `${i18n.t("queueIntegrationModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={integration}
          enableReinitialize={true}
          validationSchema={DialogflowSchema}
          onSubmit={(values, actions, event) => {
            setTimeout(() => {
              handleSaveDialogflow(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form className={classes.mainPaper} style={{ width: "100%" }}>
              <Paper square className={classes.mainPaper} elevation={1}>
                <DialogContent dividers>
                  <Grid container spacing={1} className={classes.mainPaper} >
                    <Grid item xs={12} md={6} xl={6}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        margin="dense"
                        fullWidth
                      >
                        <InputLabel id="type-selection-input-label">
                          {i18n.t("queueIntegrationModal.form.type")}
                        </InputLabel>
                        <Field
                          as={Select}
                          label={i18n.t("queueIntegrationModal.form.type")}
                          id="type"
                          name="type"
                          labelId="profile-selection-label"
                          error={touched.type && Boolean(errors.type)}
                          helpertext={touched.type && errors.type}                          
                          required
                          // onChange={(e) => setSelectedType(e.target.value)}
                          // value={selectedType}
                        >
                          <MenuItem key="n8n" value="n8n">n8n</MenuItem>
                          <MenuItem key="openai" value="openai">OpenAI</MenuItem>
                          <MenuItem key="typebot" value="typebot">Typebot</MenuItem> 
                          <MenuItem key="asaas" value="asaas">Asaas</MenuItem>
                          <MenuItem key="ixc" value="ixc">IXC Provedor</MenuItem>
                          <MenuItem key="mk-auth" value="mk-auth">Mk-Auth Provedor</MenuItem>
                          <MenuItem key="webhook" value="webhook">WebHook</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>

                    {(values.type === "mk-auth") && (
                      <>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.name")}
                            autoFocus
                            required
                            name="name"
                            error={touched.name && Boolean(errors.name)}
                            helpertext={touched.name && errors.name}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid> 
                        <Grid item xs={12} md={12} xl={12} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.urlApi")}
                            name="urlApi"
                            error={touched.urlApi && Boolean(errors.urlApi)}
                            helpertext={touched.urlApi && errors.urlApi}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.clientId")}
                            name="clientId"
                            error={touched.clientId && Boolean(errors.clientId)}
                            helpertext={touched.clientId && errors.clientId}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid> 
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.clientSecret")}
                            name="clientSecret"
                            error={touched.clientSecret && Boolean(errors.clientSecret)}
                            helpertext={touched.clientSecret && errors.clientSecret}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid> 
                      </>
                    )}

                    {(values.type === "ixc") && (
                      <>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.name")}
                            autoFocus
                            required
                            name="name"
                            error={touched.name && Boolean(errors.name)}
                            helpertext={touched.name && errors.name}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid> 
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.urlApi")}
                            name="urlApi"
                            error={touched.urlApi && Boolean(errors.urlApi)}
                            helpertext={touched.urlApi && errors.urlApi}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.tokenApiKey")}
                            name="tokenApiKey"
                            error={touched.tokenApiKey && Boolean(errors.tokenApiKey)}
                            helpertext={touched.tokenApiKey && errors.tokenApiKey}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid> 
                      </>
                    )}
 
                    {(values.type === "asaas") && (
                      <>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.name")}
                            autoFocus
                            required
                            name="name"
                            error={touched.name && Boolean(errors.name)}
                            helpertext={touched.name && errors.name}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid> 
                        <Grid item xs={12} md={12} xl={12} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.urlApi")}
                            name="urlApi"
                            error={touched.urlApi && Boolean(errors.urlApi)}
                            helpertext={touched.urlApi && errors.urlApi}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} xl={12} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.tokenApiKey")}
                            name="tokenApiKey"
                            error={touched.tokenApiKey && Boolean(errors.tokenApiKey)}
                            helpertext={touched.tokenApiKey && errors.tokenApiKey}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid> 
                      </>
                    )}

                    {(values.type === "openai") && (
                      <>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.name")}
                            autoFocus
                            required
                            name="name"
                            error={touched.name && Boolean(errors.name)}
                            helpertext={touched.name && errors.name}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid> 
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.tokenApiKey")}
                            name="tokenApiKey"
                            error={touched.tokenApiKey && Boolean(errors.tokenApiKey)}
                            helpertext={touched.tokenApiKey && errors.tokenApiKey}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} >  
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            margin="dense"
                            fullWidth
                          >
                            <InputLabel id="apiVersion-selection-input-label">
                              {'Model/Versão'}
                            </InputLabel>
                            <Field
                              as={Select}
                              label={'Model/Versão'} 
                              name="apiVersion"
                              labelId="apiVersion-selection-label"
                              error={touched.apiVersion && Boolean(errors.apiVersion)}
                              helpertext={touched.apiVersion && errors.apiVersion}                          
                              required 
                            > 
                              <MenuItem key="turbo" value="gpt-3.5-turbo">gpt-3.5-turbo</MenuItem>
                              <MenuItem key="turbo-0301" value="gpt-3.5-turbo-0301">gpt-3.5-turbo-0301</MenuItem>
                              <MenuItem key="turbo-0613" value="gpt-3.5-turbo-0613">gpt-3.5-turbo-0613</MenuItem>
                              <MenuItem key="turbo-1106" value="gpt-3.5-turbo-1106">gpt-3.5-turbo-1106</MenuItem>
                              <MenuItem key="turbo-16k" value="gpt-3.5-turbo-16k">gpt-3.5-turbo-16k</MenuItem>
                              <MenuItem key="turbo-16k-0613" value="gpt-3.5-turbo-16k-0613">gpt-3.5-turbo-16k-0613</MenuItem>
                              <MenuItem key="turbo-instruct" value="gpt-3.5-turbo-instruct">gpt-3.5-turbo-instruct</MenuItem>
                              <MenuItem key="turbo-instruct-0914" value="gpt-3.5-turbo-instruct-0914">gpt-3.5-turbo-instruct-0914</MenuItem>
                            </Field>
                          </FormControl>                          
                        </Grid>
                        <Grid item xs={12} md={12} xl={12} >  
                          <Field
                            as={TextField}
                            label={'Prompt | Explique à IA o que deve fazer'}
                            name="jsonContent"
                            error={touched.jsonContent && Boolean(errors.jsonContent)}
                            helperText={touched.jsonContent && errors.jsonContent}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            rows={10}
                            multiline={true} 
                          />                                                  
                        </Grid>
                        <Grid item xs={12} md={4} xl={4} >  
                          <FormControl>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={sandbox}
                                  onChange={handleSandboxChange}
                                />
                              }
                              label="Assinar mensagens da IA"
                            />
                            <FormHelperText>
                              {sandbox 
                                ? "As mensagens serão assinadas"
                                : "As mensagens não serão assinadas"}
                            </FormHelperText>
                          </FormControl>                                                                     
                        </Grid>
                        <Grid item xs={12} md={4} xl={4} >  
                          <ItemSelect 
                            fullWidth
                            disabled={isSubmitting}
                            title={'Tipo de resposta'}
                            selectedValues={subtype || 'texto'}
                            items={subtypes} 
                            onChange={handleSubtypeChange} 
                            labelWidth={145} 
                          />                                                                       
                        </Grid>
                        <Grid item xs={12} md={4} xl={4} >  
                          <ItemSelect 
                            fullWidth
                            disabled={isSubmitting}
                            title={'Fila para transferência'}
                            selectedValues={queueId || '0'}
                            items={itemsQueues} 
                            onChange={handleQueueIdChange} 
                            labelWidth={165} 
                          />                                                                           
                        </Grid>
                        <Grid item xs={12} md={4} xl={4} >
                          <TextField
                            label="Temperatura | 0.1 - 2"
                            name="temperature"
                            type="number"
                            inputProps={{
                              step: 0.01,
                              min: 0.1,
                              max: 2,
                            }}
                            value={temperature} 
                            onChange={event =>
                              setTemperature(event.target.value)
                            }
                            error={touched.temperature && Boolean(errors.temperature)}
                            helperText={touched.temperature && errors.temperature}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />                       
                        </Grid> 
                        <Grid item xs={12} md={4} xl={4} >
                          <TextField
                            type="number" 
                            label={'Máx. de tokens na resposta'}
                            name="maxTokens"
                            value={maxTokens}
                            onChange={event =>
                              setMaxTokens(event.target.value)
                            }
                            error={touched.maxTokens && Boolean(errors.maxTokens)}
                            helpertext={touched.maxTokens && errors.maxTokens}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />                       
                        </Grid>  
                        <Grid item xs={12} md={4} xl={4} >
                          <TextField
                            type="number"
                            label={'Máx. de mensagens no histórico'}
                            name="maxMessages"
                            value={maxMessages}
                            onChange={event =>
                              setMaxMessages(event.target.value)
                            }
                            error={touched.maxMessages && Boolean(errors.maxMessages)}
                            helpertext={touched.maxMessages && errors.maxMessages}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />                       
                        </Grid>
                      </>
                    )}

                    {(values.type === "n8n" || values.type === "webhook") && (
                      <>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.name")}
                            autoFocus
                            required
                            name="name"
                            error={touched.name && Boolean(errors.name)}
                            helpertext={touched.name && errors.name}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} xl={12} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.urlApi")}
                            name="urlApi"
                            error={touched.urlApi && Boolean(errors.urlApi)}
                            helpertext={touched.urlApi && errors.urlApi}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                      </>
                    )}

                    {(values.type === "typebot") && (
                      <>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.name")}
                            autoFocus
                            name="name"
                            error={touched.name && Boolean(errors.name)}
                            helpertext={touched.name && errors.name}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.urlApi")}
                            name="urlApi"
                            error={touched.urlApi && Boolean(errors.urlApi)}
                            helpertext={touched.urlApi && errors.urlApi}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.typebotSlug")}
                            name="typebotSlug"
                            error={touched.typebotSlug && Boolean(errors.typebotSlug)}
                            helpertext={touched.typebotSlug && errors.typebotSlug}
                            required
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} xl={12} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.tokenApiKey")}
                            name="tokenApiKey"
                            error={touched.tokenApiKey && Boolean(errors.tokenApiKey)}
                            helpertext={touched.tokenApiKey && errors.tokenApiKey}
                            variant="outlined"
                            margin="dense" 
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid> 
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.typebotExpires")}
                            name="typebotExpires"
                            error={touched.typebotExpires && Boolean(errors.typebotExpires)}
                            helpertext={touched.typebotExpires && errors.typebotExpires}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.typebotDelayMessage")}
                            name="typebotDelayMessage"
                            error={touched.typebotDelayMessage && Boolean(errors.typebotDelayMessage)}
                            helpertext={touched.typebotDelayMessage && errors.typebotDelayMessage}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.typebotKeywordFinish")}
                            name="typebotKeywordFinish"
                            error={touched.typebotKeywordFinish && Boolean(errors.typebotKeywordFinish)}
                            helpertext={touched.typebotKeywordFinish && errors.typebotKeywordFinish}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.typebotKeywordRestart")}
                            name="typebotKeywordRestart"
                            error={touched.typebotKeywordRestart && Boolean(errors.typebotKeywordRestart)}
                            helpertext={touched.typebotKeywordRestart && errors.typebotKeywordRestart}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.typebotUnknownMessage")}
                            name="typebotUnknownMessage"
                            error={touched.typebotUnknownMessage && Boolean(errors.typebotUnknownMessage)}
                            helpertext={touched.typebotUnknownMessage && errors.typebotUnknownMessage}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} >
                          <Field
                            as={TextField}
                            label={i18n.t("queueIntegrationModal.form.typebotRestartMessage")}
                            name="typebotRestartMessage"
                            error={touched.typebotRestartMessage && Boolean(errors.typebotRestartMessage)}
                            helpertext={touched.typebotRestartMessage && errors.typebotRestartMessage}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            className={classes.textField}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </DialogContent>
              </Paper>

              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("queueIntegrationModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {currentId
                    ? `${i18n.t("queueIntegrationModal.buttons.okEdit")}`
                    : `${i18n.t("queueIntegrationModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div >
  );
};

export default QueueIntegration;